@import '/src/Components/00.base/styles/variables';

.container {
  a {
    font-weight: 700;
    color: $primary-navigation;
    text-decoration: none;
    transition: $transition;

    &:hover .dealName {
      color: $ravenclaw;
    }
  }

  .dealName{
    display: flex;
    gap: 10px;
    transition: $transition;
  }

  .iconContainer {
    display: flex;
    flex-direction: column;
  }

  .dealWrapper {
    display: block;
  }

  .dealId {
    display: flex;
    flex-shrink: 1;
    margin-top: 10px;

    .wrapper {
      color: $warm-blue;
      padding: 5px;
      font-weight: 800;
      font-size: $fs-sm;
      text-transform: uppercase;
    }
  }
}

.textContainer {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  display: flex;
  flex-direction: row;
}

svg.verifiedIcon {
  fill: $warm-blue;
  width: 15px;
  height: 15px;
  margin: 1px 0 0 3px ;
}