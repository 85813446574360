@import '/src/Components/00.base/styles/variables';

.step-inputs-form {
    width: 100%;
}

.step-inputs-form .input-container {
    font-size: 18px;
    margin: 0 0 25px;
}

.step-inputs-form .input-container.remove-margin, .create-deal-container .input-container.remove-margin {
    margin: 0;
}

.step-inputs-form .input-container .input-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $input-label;
}

.step-inputs-form .input-container.error input,
.step-inputs-form .input-container.error .select-container {
    border: 1px solid red;
}

.step-inputs-form .input-container input {
    width: 100%;
    font-size: 18px;
    border: 1px solid $input-border;
    margin-top: 10px;
    padding: 13px 10px;
    box-sizing: border-box;
    border-radius: 4px;
}

.step-inputs-form .manage-deal--quote-step {
    div:focus-visible,
    &:focus-visible {
        outline: none;
    }

    .input-container input {
        padding: 0 10px;
    }

    .quote-container {
        margin: 0;
        width: 100%;
    }
}

.MuiBox-root input {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}

.step-inputs-form .input-container input:focus {
    outline: 1px solid $input-border-selected;
}

.step-inputs-form .input-container input:disabled {
    background-color: $input-disabled;
}

.step-inputs-form .input-container input.date-field:disabled {
    background: none;
}

.step-inputs-form .input-container .error {
    color: red;
    font-size: 14px;
}

.step-inputs-form .buttons {
    width: 100%;
    display: block;
}

.confirmation-container .buttons {
    display: flex;
    justify-content: space-evenly;
}

.step-inputs-form .buttons .save-buttons {
    display: block;
    text-align: right;
}

.save-buttons__tooltip-wrap {
    display: inline-block;
    margin-right: 10px;
}

.step-inputs-form .discard-button {
    border: 2.5px solid #adadad;
    border-radius: 3px;
    background-color: #FFF;
    color: #adadad;
    font-weight: bold;
    padding: 20px 40px;
    cursor: pointer;
    margin-right: 15px;
}

.step-inputs-form .discard-button:hover {
    outline: 1px solid #adadad;
}

.step-inputs-form .save-button {
    padding: 20px 70px;
    font-weight: bold;
    border-radius: 2px;
    background-color: #E91E63;
    color: #FFF;
}

.step-inputs-form .save-buttons .loading {
    user-select: none;
    height: 60px;
    width: 180px;
    border-radius: 2px;
    background-color: #c0c0c0;
    border: 1px solid #adadad;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.step-inputs-form .save-button:hover {
    background-color: #FFF;
    color: #E91E63;
}

.step-inputs-form .new-sample-button {
    padding: 20px 20px;
    font-weight: bold;
}

.step-inputs-form .options-list.opened {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
}

.step-inputs-form #selected-input {
    padding: 0;
    margin: 0;
}

.step-details-container .step-inputs-form .groupe {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
 }

.step-details-container .step-inputs-form .groupe .input-container {
    margin: 0;
}
.step-details-container .step-inputs-form .groupe .groupe-item {
    width: 48%;
 }

.popup-title {
    margin-bottom: 20px;
}

@media (min-width: 320px) {
    .step-inputs-form .buttons .save-buttons {
        display: initial;
    }

    .step-inputs-form .new-sample-button {
        margin: 10px 0;
    }

    .step-inputs-form .buttons .save-buttons button {
        margin: 10px 0;
        width: 100%;
    }
}

@media (min-width: 480px) {
    .step-inputs-form .buttons .save-buttons {
        display: flex;
        justify-content: space-between;
        text-align: right;

        div:last-child {
            margin-left: auto;
          }
    }

    .step-inputs-form .buttons .save-buttons button {
        width: auto;
    }
}

@media (max-width: 480px) {
    .save-buttons__tooltip-wrap {
        display: block;
        margin-left: 0;
    }
}

