@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.hamburger-menu-container {
  color: #000;
  z-index: 100;

  section {
    height: 100%
  }

  .menu-bars {
    cursor: pointer;
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .bar {
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 15px;
    }
  }

  .full-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.397);
    touch-action: none;
  }
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #FFF;
  width: 100%;

  .user-name {
    z-index: 2;
    border-top: 1px solid $input-border;
    padding: 20px 10px 20px 20px;

    a {
      display: inline-block;
      text-decoration: none;
      color: $primary-text-default;
    }

    p {
      display: inline-block;
      margin-left: 10px;
      font-size: $fs-md;
    }

    svg {
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }

  .top-line {
    height: 60px;
    width: 100%;
    border-bottom: 2px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: $secondary-bg-light;
    color: $secondary-bg-text-light;
    text-transform: capitalize;

    p {
      font-weight: 500;
    }

    .close-button {
      padding: 15px;
      cursor: pointer;

      path {
        fill: #FFF
      }
    }
  }

  .navigation-list {
    z-index: 1;
    display: block;
    position: relative;
    flex: 1 1 auto;
    overflow-x: scroll;
    margin: 0 0 40px;
    padding: 15px 20px;

    & > a, & > div {
      display: block;
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px solid $primary-separation-line-grey;
    }

    a {
      font-weight: 500;
      color: #000;
      text-decoration: none;
    }
  }
}

@include media(sm) {
  .hamburger-menu {
    width: 70%;
  }
}
