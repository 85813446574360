@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.container {
  margin: 0 auto 20px;
  border: 3px solid $primary-box-border;
  padding: 10px 20px;
  text-align: left;
  font-size: $fs-md;
}

.topHeader {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.muteIcon {
  display: inline-block;
  width: 50%;

  .mutedEmailIcon {
    fill: $secondary-icon;
  }
}

.draft {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.titleWrap {
  margin-bottom: 20px;

  h2, div h2 {
    font-size: $fs-e;
    margin-bottom: 0;
  }

  .subTitle {
    margin-bottom: 40px;
  }
}

.productsContainer {
  margin-bottom: 20px;
}

.productWrap {
  margin-bottom: 20px;
}

.productTitle {
  font-size: $fs-normal;
  font-weight: 700;
}

.productSubTitle {
  display: flex;
  justify-content: space-between;
}

.infoLine, .messageModification {
  margin-bottom: 20px;

  h3 {
    color: $primary-text-grey-bold;
    font-size: $fs-normal;
  }

  section {
    color: $primary-text-grey-bold;
  }
}

.heading {
  margin-bottom: 20px;

  h3 {
    color: $primary-text-grey-bold;
    font-size: $fs-normal;
  }
}

.subInfo {
  color: $primary-text-grey-bold;
  font-size: $fs-normal;
}

.numberedLine {
  margin-bottom: 40px;
  list-style: none;
  counter-reset: item;
}

li {
  margin-bottom: 10px;
}

.text {
  display: inline-block;
  width: calc(100% - 30px);
  vertical-align: top;
  font-size: $fs-md;

  label {
    font-size: $fs-md;
  }
}

.buttonsWrap {
  text-align: right;
}

.buttonsInnerWrap {
  button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}

.buttonsInnerWrapFlex {
  & > div {
    justify-content: space-between;
  }
}

@include media(md) {
  .page {
    margin: 0 auto;
    width: 600px;
  }

  .buttonsInnerWrap {
    button {
      display: inline-block;
      width: auto;
      margin-bottom: 0;
    }

    button:first-child {
      margin-right: 145px;
      padding: 15px 0;
      min-width: auto;
    }

    button:last-child {
      margin-left: 12px;
    }
  }
}

.stepperWrap {
  display: flex;
  margin-bottom: 20px;

  .stepperLabel {
    padding-top: 2px;
    width: 100px;
    font-weight: 700;
    color: $primary-text-grey-bold;
  }
}
