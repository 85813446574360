@import '/src/Components/00.base/styles/variables';

.select-inventory-container {
    position: relative;
    background-color: #FFF;
    border: 1px solid $input-border;
    cursor: pointer;
    color: rgb(102, 102, 102);
    border-radius: 4px;
    margin-top: 10px;
}

.select-inventory-container.error{
    border: 1px solid $error-color;
}

.select-inventory-container:focus-visible{
    outline: none;
}

.select-inventory-container .choice {
    display: flex;
    width: calc(100% - 20px);
    height: 100%;
    padding: 13px 10px;
    align-items: center;
}

.select-inventory-container #selected-input{
    pointer-events: none;
    display: inline-block;
    border: none;
    width: calc(100% - 80px);
    font-size: 18px;
    color: rgb(102, 102, 102);
}

.select-inventory-container .choice .select-inventory__btn-wrap {
    display: inline-block;
    width: 80px;
    text-align: right;
}

.select-inventory-container .choice button {
    display: inline-block;
}

.select-inventory-container .choice .select-inventory-chevron {
    display: inline-block;
}

.select-inventory-container #selected-input:disabled {
    background: none;
    cursor: pointer;
}

.select-inventory-container #selected-input:focus-visible{
    outline: none;
}

.options-list{
    display: none;
}

.options-list.opened{
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 9px 15px -3px rgba(0,0,0,0.39);
    z-index: 1000;
}

.options-list .option-container{
    cursor: pointer;
    min-height: 30px;
    margin: 5px 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $input-border;
}

.options-list .option-container .name{
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.options-list .option-container .code{
    font-size: 13px;
}

.options-list .option-container.highlight{
    background-color: $input-border;
}

.error-container{
    color: red;
    font-size: 15px;
}