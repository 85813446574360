@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/animations';

.layoutWrap {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  animation: appear 0.5s;

  .contentWrap {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    padding: 10px 20px 20px;
    // min-width: 400px;
    // max-width: 500px;
    width: 650px;
    max-height: 650px;
    background: $primary-bg-light;
    overflow: auto;
    /* transform: translate(0, -70%); */

    h3 {
      margin-bottom: 20px;
      max-width: 90%;
      font-size: $fs-xl;
    }

    .textarea {
      resize: none;
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid $primary-box-border;
      border-radius: 4px;
      padding: 8px 16px;
      width: 100%;
      height: 110px;
      box-sizing: border-box;
      font-size: 18px;
    }

    .buttonsWrap {
      margin-top: $item-bottom-margin;
    }

    .flexEnd {
      display: flex;
      justify-content: end;
      column-gap: $item-side-margin;
    }
  }
}

.closeButton {
  z-index: 5;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  svg {
    display: block;
    width: 12px;
    height: 12px;
  }
}

.btnWrap {
  margin-top: $item-bottom-margin;
  text-align: right;

  button {
    display: block;
    width: 100%;
  }
}

.textAreaWrap {
  margin-top: 20px;
  border-top: 3px solid $secondary-box-border;
  padding-top: 20px;

  p {
    font-weight: 500;
    font-size: $fs-normal;
    color: $primary-text-grey-bold;
  }
}


@media only screen and (max-width: 800px) {
  .layoutWrap {
    .contentWrap {
      width: 60%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .layoutWrap {
    .contentWrap {
      width: 80%;
      top: 10%;
    }
  }
}