@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import '/src/Components/00.base/styles/variables';

* {
  margin: 0;
  padding: 0;
  outline: none;
}


.header__search-container {
  .outlined-input-component {
    .MuiInputBase-root {
      &:hover,
      &:focus-visible,
      &:focus,
      &.Mui-focused {
        border: none;
        outline: none;

        fieldset {
          border: none;
          outline: none;
        }
      }
      input {
        padding: 0;
        min-width: 200px;
        font-size: $fs-sm;
      }

      fieldset {
        border: none;
      }
    }
  }
}
