@import '/src/Components/00.base/styles/variables';

.inputWrap {
  position: relative;

  & > div {
    margin-bottom: 10px;
  }

  input {
    margin-top: 0;
    padding-left: 5px;
    padding-bottom: 5px;
    border: none;
    border-bottom: 1px solid $input-border;
    max-width: 90%;
  }

  .icon {
    top: 10px;

    svg {
      font-size: 30px;
    }
  }
}

.title {
  position: relative;
}

.header {
  display: inline-block;
  max-width: 90%;
}

.icon {
  position: absolute;
  top: 7px;
  right: 0;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: $primary-icon;
    transition: $transition;

    &:hover {
      color: $secondary-icon;
    }
  }
}
