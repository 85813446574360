@import "/src/Components/00.base/styles/variables";


.title {
  font-weight: bold;
  
  svg {
    &.verified {
      fill: $warm-blue;
    }
  }
}