@import '/src/Components/00.base/styles/variables';

.textarea {
    font-size: 18px;
    resize: none;
    padding: 8px 16px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $input-border;

    &:focus {
        outline: 1px solid $input-border-selected;
    }

    &:disabled {
        outline: 1px solid $input-border;
        background-color: $input-disabled;
    }
}

.info {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
}

.counter {
    width: 100%;
    text-align: right;
}

.message {
    width: 100%;
    text-align: left;
}