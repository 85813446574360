.emptySelect {
  margin-top: 10px;
  border: 1px solid #efebeb;
  padding: 0 10px;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  color: rgb(102, 102, 102);
  font-size: 17.5px;
  line-height: 20px;
}