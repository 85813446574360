@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.infoWrap {
  text-align: center;
}

.imgWrap {
  display: none;
}

.title {
  margin-bottom: 20px;
  font-size: 150px;
  color: $primary-text-grey;
}

.subTitle {
  margin-bottom: 40px;
  font-size: $fs-xl;
}

@include media (lg) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .infoWrap {
    margin-top: 100px;
  }

  .imgWrap {
    display: block;
  }
}