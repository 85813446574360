@mixin media($point) {
  @if $point == xs {
    @media only screen and (min-width: 320px) {@content;}
  }

  @if $point == sm {
    @media only screen and (min-width: 481px) {@content;}
  }

  @else if $point == max-sm {
    @media only screen and (max-width: 482px) {@content;}
  }

  @else if $point == max-md {
    @media only screen and (max-width: 768px) {@content;}
  }

  @else if $point == md {
    @media only screen and (min-width: 769px) {@content;}
  }

  @else if $point == lg {
    @media only screen and (min-width: 1024px) {@content;}
  }

  @else if $point == max-lg {
    @media only screen and (max-width: 1023px) {@content;}
  }

  @else if $point == xl {
    @media only screen and (min-width: 1280px) {@content;}
  }

  @else if $point == xxl {
    @media only screen and (min-width: 1400px) {@content;}
  }  
}