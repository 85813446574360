@import '/src/Components/00.base/styles/variables';

.container {
}

.stepsPagination {
  display: flex;
  flex-wrap: wrap;

  .button {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 50%;
    padding: 10px;
    width: 25px;
    height: 25px;
    background-color: $primary-btn-bg-grey;
    cursor: pointer;
    text-align: center;

    svg {
      color: $primary-btn-text-grey;
    }

    &.stepDone {
      background-color: $primary-btn-bg-light;

      svg {
        color: $primary-btn-text-light;
      }
    }

    &.addSample {
      margin-left: auto;
      background-color: $primary-btn-bg-grey;
      transition: $transition;

      svg {
        color: $primary-btn-text-grey;
      }

      &:hover{
        background: $primary-btn-bg-light;

        svg {
          transition: $transition;
          color: $primary-btn-text-light;
        }
      }
    }

    &.active {
      background-color: $greenish-yellow;

      svg {
        color: $primary-btn-text-grey;
      }
    }
    &.approved {
      background-color: $secondary-bg-light;

      svg {
        color: $primary-btn-text-dark;
      }
    }
    &.rejected {
      background-color: $secondary-icon;

      svg {
        color: $primary-btn-text-dark;
      }
    }
    &.current {
      outline: 3px solid $primary-btn-text-grey;
      outline-offset: -3px;
    }
    &.aprNotCompleted{
      background-color: $step-bg-orange;

      svg {
        color: $primary-btn-text-dark;
      }
    }
  }
}

