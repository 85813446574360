@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.container {
  display: flex;

  h3 {
    b {
      text-transform: uppercase;
    }
  }
}

.headerWrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  margin-bottom: $item-bottom-margin;

  h2 {
    padding-top: 10px;
  }
}

.settingsSection {
  width: 100%;
}

.saveButtonWrap {
  margin-top: $item-bottom-margin;
  margin-bottom: $item-bottom-margin;
  border-top: 3px solid $primary-box-border;
  padding-top: $item-bottom-margin;
  text-align: right;
}

@include media(lg) {
  .settingsSection {
    display: inline-block;
    width: 55%;
  }
}
