@import '/src/Components/00.base/styles/variables';

.edit-deal-body {
  #selected-input {
    padding: 0;
  }

  .title {
    font-size: 18px;
  }

  .field-error {
    margin-bottom: 20px;

    p {
      font-size: inherit;
    }
  }

  .no-references {
    font-size: 15px;
    color: #666666;
  }

  .trader {
    margin-bottom: 20px;
  }

  .remaining-docs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 65%;
  }

  .input-wrap {
    position: relative;

    .input-loader {
      position: absolute;
      top: 25px;
      right: 10px;
    }
  }
}

.edit-deal-container {
  width: calc(100% - 40px);
  margin: 15px 20px;

  .head {
    display: flex;
    margin-bottom: 30px;
    color: rgb(70, 70, 70);

    h3 {
      font-size: 26px;
    }
  }

  .checkmark .check {
    display: none;
  }

  .steps-container {
    margin-top: 30px;

    &.error .checkmark {
      border: 1px solid red;
    }

    .error-container {
      font-size: 15px;
      color: red;
    }

    &>p {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .step {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      margin-bottom: 13px;

      &.disabled {
        cursor: default;
      }

      input {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }
  }

  .edit-deal-body {
    display: flex;
    flex-direction: column;

    .references-titile {
      margin-bottom: 20px;
      font-weight: bold;
    }

    .reference-container {
      margin-bottom: 30px;
      margin-top: 30px;

      input {
        font-size: 18px;
        min-height: 50px;
        padding: 10px 25px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 5px;
        border: 1px solid $input-border;
        color: rgb(102, 102, 102);
      }

      .new-reference-button {
        margin-top: 10px;
        padding: 5px 10px;
        width: 200px;
      }
    }

    .reference {
      display: flex;
      gap: 25px;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      p {
        font-size: 14px;
      }

      .delete-ref {
        border: none;
        background-color: transparent;
        color: #E91E63;
        font-weight: bold;
        cursor: pointer;
        width: 27px;
        height: 50px;
        font-size: 15px;

        svg {
          fill: $primary-btn-bg-dark;
        }
      }
    }

    .fee-container {
      .input-wrap {
        display: inline-block;
        margin-right: 5px;
        max-width: 90px;

        .section-input {
          max-width: 50px;
          text-align: center;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }

      p.title {
        display: inline-block;
      }
    }

    .inputs-wrap .section-input {
      min-height: 50px;
      line-height: 50px;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 10px;
      border: 1px solid #efebeb;
      font-size: 17.5px;
      color: rgb(102, 102, 102);
    }

    .inputs-wrap section-input.error {
      border: 1px solid red;
    }

    .buyer .error-text,
    .seller .error-text,
    .name .error-text,
    .inputs-wrap .error-text {
      color: red;
      font-size: 14px;
    }
  }

  .select-container,
  .select-inventory-container {
    min-height: 50px;
    width: 100%;
    box-sizing: border-box;
    //margin-top: 10px;
    border: 1px solid #efebeb;
  }

  .select-container input {
    color: #000 !important;
    font-size: 17.5px !important;
  }

  .readonly-input {
    margin-top: 10px;
    border: 1px solid #efebeb;
    padding: 0 10px;
    width: 100%;
    min-height: 50px;
    box-sizing: border-box;
    background-color: #F9F9F9;
    color: rgb(102, 102, 102);
    font-size: 17.5px;
    line-height: 50px;
  }

  .options-list.opened {
    max-height: 40vh;
    overflow-y: auto;
    width: 100%;
    top: 43px;
  }

  .checkmark {
    height: 15px;
    width: 15px;
    background-color: $primary-text-light;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .step.disabled .checkmark {
    background-color: $input-disabled;
  }

  .buttons-container {
    margin-top: 20px;
    text-align: right;

    .loader {
      margin-top: 20px;
      align-items: center;
      user-select: none;
    }

    button {
      margin-left: 20px;
    }
  }

  .loader {
    display: flex;
    gap: 10px;
    color: $primary-text-light;
    font-weight: 700;
    font-size: 13.3px;
    margin-top: 34px;
    margin-left: 22px;
    align-items: flex-end;
    user-select: none;
  }

  .edit-deal-button {
    padding: 12px 20px;
    margin-right: 20px;
    font-weight: 700;
    margin-top: 20px;
  }

  .update-deal-button,
  .new-reference-button {
    padding: 12px 20px;
    margin-right: 20px;
    font-weight: 700;

    &:hover {
      outline: 1px solid $primary-btn-bg-light;
    }
  }

  .new-reference-button:hover {
    background-color: $primary-btn-bg-light;
    color: $primary-btn-text-light;
  }

  .update-deal-button {
    margin-left: 20px;
    color: #FFF;
    background-color: #E91E63;
  }

  .organization-name {
    margin-top: 10px;
    font-size: 14px;
    color: #3c3c3c;
  }

  .mute-mail--wrap {
    margin-top: 20px;
    margin-bottom: 10px;

    .mute__text {
      display: inline-block;
      vertical-align: top;
      padding-top: 6px;
      margin-left: 10px;
    }

    .mute__switcher {
      display: inline-block;

      .react-switch-handle {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 25%);
      }
    }
  }

}

.checkmark.checked .check {
  display: block;
}

.loader {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 320px) {
  .edit-deal-body {
    .reference-type {
      width: 100%;
    }

    .reference {
      flex-wrap: wrap;
      padding-bottom: 20px;
      padding-top: 20px;

      &+.reference {
        border-top: 1px solid #efebeb;
        width: 100%;
      }

      .delete-ref {
        width: 27px;
      }
    }

    .reference-value {
      width: calc(89% - 27px);
    }
  }

  .edit-deal-container {
    .buttons-container {
      button {
        margin: 0 0 10px;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .edit-deal-container {
    .edit-deal-button {
      width: 100%;
    }

    .update-deal-button {
      width: 30%;
    }

    .buttons-container {
      grid-column-gap: 15px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .edit-deal-container {
    .buttons-container {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0;
        width: auto;
      }
    }
  }

  .edit-deal-body {
    padding-right: 40px;

    .reference {
      flex-wrap: initial;
    }

    .reference-value {
      width: 45%;
    }

    .reference-type {
      width: 45%;

      .select-container {
        margin-bottom: 0;
      }
    }
  }
}


@media only screen and (min-width: 1161px) {
  .edit-deal-container {
    margin: 0 auto;
    width: 55%;
  }

  .edit-deal-body {
    padding-right: 0 !important;
  }
}