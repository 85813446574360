@import '/src/Components/00.base/styles/variables';

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 15px;
    height: 15px;
    border: 2px solid $primary-btn-bg-grey;
    border-top: 2px solid $primary-text-light;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}