/* Color Naming Convention
  - primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary, and denar
  - type of the element: text, btn, link and etc.
  - secondary type of the element: border, text and etc.
  - theme: light, dark, grey and etc.
  - state: hover, active and etc.
*/

/* ========= Color Names ========= */
$white: #fff;
$black: #000;
$dune: #333;
$kelly-green: #4fb51e;
$tree-green: #327313;
$dawn-pink: #EFEBEA;
$pink-swan: #bdb9b9;
$river-bed: #495057;
$snowy-mint: #ddffdd;
$comet: #5B6275;
$venetian-red: #d40014;
$davy-grey: #5c5556;
$oslo-grey: #8b8b8b;
$red-fox: #BD5E1D;
$azure: #00a2e8;
$rosso-corsa: #DF0000;
$greenish-yellow: #E3F705;
$gamboge: #E29300;
$snow-drift: #F9F9F9;
$cloudy-blue: #aac2dd;
$nile-blue: #183153;
$lava-red: #ed1c24;
/* ========= New Color Names ========= */
$warm-blue: #4241DD;
$ravenclaw: #060547;
$pastel-grey: #F3F4F6;
$primal-rage: #F53C1E;
$apricot: #F1A827;

/* TEXT Color */
/* DEFAULT */
$primary-text-default: $dune;
$primary-text-bold-default: $dune;
/* Light */
$primary-text-light: $warm-blue;
$primary-text-bold-light: $tree-green;
$secondary-text-light: $tree-green;
$primary-text-pending: $azure;
/* Dark */
$primary-text-dark: $venetian-red;
/* GREY */
$primary-text-grey: $river-bed;
$primary-text-grey-bold: $comet;
$secondary-text-grey: $oslo-grey;
$tertiary-text-grey: $davy-grey;

/* NAVIGATION COLORS */
$primary-navigation: $dune;
$primary-navigation-hover: $warm-blue;

/* LINK */
$primary-link: $warm-blue;
$primary-link-hover: $tree-green;

/* INPUT COLORS */
$placeholder-text: $river-bed;
$input-label: $dune;
$input-border: $dawn-pink;
$input-border-selected: $pink-swan;
$input-disabled: $dawn-pink;
$input-text-disabled: $pink-swan;
$dropdown-shadow: 0 9px 15px -3px rgba(0,0,0,.39);
$select-arrow: #757575;
$select-dropdown-selected: #f5f5f5;

/* BUTTON COLOR */
/* Light */
$primary-btn-bg-light: $warm-blue;
$primary-btn-text-light: $white;
$primary-btn-border-light: $warm-blue;
$primary-btn-bg-light-hover: $white;
$primary-btn-text-light-hover: $warm-blue;
$primary-btn-bg-disabled: $river-bed;
/* Dark */
$primary-btn-bg-dark: $venetian-red;
$primary-btn-text-dark: $white;
$primary-btn-bg-dark-border: $venetian-red;
$secondary-btn-dark: $rosso-corsa;
$secondary-btn-text-dark: $white;
$secondary-btn-bg-dark-border: $rosso-corsa;
$primary-btn-bg-dark-hover: $white;
$primary-btn-text-dark-hover: $venetian-red;
$primary-btn-bg-dark-border-hover: $venetian-red;
/* Grey */
$primary-btn-bg-grey: $dawn-pink;
$primary-btn-text-grey: $dune;
$secondary-btn-bg-grey: $river-bed;
$secondary-btn-text-grey: $white;
$tertiary-btn-bg-grey: $davy-grey;
/* Blue */
$primary-btn-bg-blue: $azure;
$primary-btn-text-blue: $white;
$primary-btn-border-blue: $azure;
/* Red */
$primary-remove-btn-bg: $lava-red;

/* Orange*/
$btn-bg-orange: $red-fox;
$step-bg-orange: $gamboge;

/* Background colors */
$primary-bg-light: $white;
$primary-bg-dark: $black;
$primary-bg-grey: $dawn-pink;
$secondary-bg-light: $warm-blue;
$secondary-bg-text-light: $white;
$secondary-bg-grey: $snow-drift;
$secondary-bg-dark: $ravenclaw;

/* BOX */
$primary-box-border: $dawn-pink;
$border-radius: 4px;
$secondary-box-border: $pink-swan;
$item-bottom-margin: 20px;
$section-bottom-margin: $item-bottom-margin * 2;
$item-side-margin: 20px;
$box-shadow: 0 6px 12px rgba(0, 0, 0, 18%);

/* Separation Line*/
$primary-separation-line-grey: $pink-swan;
$primary-separation-line-light: $warm-blue;

/* Notifications */
$success-bg: $snowy-mint;
$success-text: $dune;
$success-border: $pink-swan;
$error-bg: $dawn-pink;
$error-color: $venetian-red;

/* ======== Icons Color ======== */
$primary-icon: $warm-blue;
$secondary-icon: $venetian-red;
$secondary-icon-hover: $dune;

/* ========= Font-size ========= */
$fs-e: 30px;
$fs-xxl: 24px;
$fs-xl: 20px;
$fs-lg: 18px;
$fs-normal: 16px;
$fs-md: 14px;
$fs-sm: 12px;
$fs-xs: 10px;

/* ========= Columns ========= */
$column-11: 91.66%;
$column-10: 83.33%;
$column-9: 75%;
$column-8: 66.66%;
$column-7: 58.33%;
$column-6: 50%;
$column-5: 41.66%;
$column-4: 33.33%;
$column-3: 25%;
$column-2: 16.66%;
$column-1: 8.33%;

/* ========= Margins ========= */
$primary-margin: 10px;

/* ========= Other ========= */
$transition: all .2s ease-in-out;

/* Variables to be used inside styled components: var(--primary)  */
:root {
  --primary-btn-bg-light: #{$primary-btn-bg-light};
  --primary-btn-text-light: #{$primary-btn-text-light};
  --primary-btn-border-light: #{$primary-btn-border-light};
  --primary-btn-bg-light-hover: #{$primary-btn-bg-light-hover};
  --primary-btn-text-light-hover: #{$primary-btn-text-light-hover};
  --primary-btn-bg-disabled: #{$primary-btn-bg-disabled};
  --primary-text-dark: #{$primary-text-dark};
  --primary-text-default: #{$primary-text-default};
  --secondary-btn-bg-grey: #{$secondary-btn-bg-grey};
  --btn-bg-orange: #{$btn-bg-orange};
  --primary-btn-bg-dark: #{$secondary-bg-dark};
}

:export {
  primaryBtnBgLight: $primary-btn-bg-light;
  primaryBtnTextLight: $primary-btn-text-light;
  primaryBtnBgDark: $primary-btn-bg-dark;
  tertiaryBtnBgGrey: $tertiary-btn-bg-grey;
  btnBgOrange:$primal-rage;
  disabled: $primary-btn-bg-disabled;
  switcherOffColor: $pink-swan;
  secondaryBtnBgDark: $ravenclaw;
  btnBgApricot: $apricot;
}