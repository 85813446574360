@import "/src/Components/00.base/styles/variables";

svg {
    &.red {
        fill: $primary-btn-bg-dark;
    }
    
    &.green {
        fill: $primary-btn-bg-light;
    }
}

.helperText {
  margin-top: 10px;
  font-size: 14px;
  color: #3c3c3c;
}

.gridItem {
    display: flex;
    justify-content: space-between;
}

.deleteAgent { 
    padding: 10;
    align-self: center;
}

.inputAgent { 
    width: 45%;
  }
  
  .permissionInput {
    width: 35%;
  }
  
  .label {
    font-weight: 500;
    font-size: 14px;
    margin-block: 0.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }