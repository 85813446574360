@import '/src/Components/00.base/styles/variables';

button {
  border: 2px solid $primary-btn-border-light;
  border-radius: 4px;
  width: auto;
  font-family: Plus Jakarta Sans, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;

  &.icon-button {
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: capitalize;
  }

  &.button-fullwidth {
    width: 100%;
  }

  .btn-icon {
    margin-right: 10px;
  }

  &.button-type--text {
    border: none;
    text-decoration: underline;
    color: $primary-btn-text-light-hover;

    &:hover {
      background: none;
      text-decoration: none;
    }
  }

  &.button-type--fill {
    border: 0;
    background-color: $primary-btn-bg-light;
    color: $primary-btn-text-light;

    &:hover {
      background-color: $secondary-bg-dark;
      color: $primary-btn-text-light;
    }
  }

  &.button-type--link {
    border: none;
    background: none;
    font-weight: bold;
    color: $primary-btn-bg-light;
    cursor: pointer;

    &:hover {
      color: $primary-text-default;
    }
  }

  &:disabled {
    background-color: $primary-btn-bg-disabled;
  }

  &.button-type--empty {
    border: 0;
    background-color: $primary-btn-bg-light;
    color: $primary-btn-text-light;

    &:hover {
      background-color: $secondary-bg-dark;
      color: $primary-btn-text-light;

      .btn-icon {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(144deg) brightness(104%) contrast(101%);
      }
    }

    a {
      color: $primary-btn-text-light-hover;
    }
  }

  &.button-type--rejected {
    background-color: #D73964;
    color: #fff;

    &:hover {
      background-color: #B41943;
      border: 2px solid #B41943;
    }
  }

  &.button-type--approved {
    border: 2px solid #7ECB7A;
    background-color: #7ECB7A;
    color: #fff;

    &:hover {
      background-color: #51A54C;
      border: 2px solid #51A54C;
    }
  }

  &.button-type--new {
    border: 2px solid #426392;
    background-color: #426392;
    color: #fff;

    &:hover {
      background-color: #1C3C6A;
      border: 2px solid #1C3C6A;
    }
  }

  &.button-type--pending-approval {
    border: 2px solid #F3C158;
    background-color: #F3C158;
    color: #fff;

    &:hover {
      background-color: #D39F32;
      border: 2px solid #D39F32;
    }
  }

  &.button-type--draft {
    border: 2px solid #513F79;
    background-color: #513F79;
    color: #fff;

    &:hover {
      background-color: #352559;
      border: 2px solid #352559;
    }
  }

  &.button-type--pending-changes {
    border: 2px solid #14B0A7;
    background-color: #14B0A7;
    color: #fff;

    &:hover {
      background-color: #0C8780;
      border: 2px solid #0C8780;
    }
  }

  &.button-type--archived {
    border: 2px solid #555555;
    background-color: #555555;
    color: #fff;

    &:hover {
      background-color: #272626;
      border: 2px solid #272626;
    }
  }

  &.button-size--xxl {
    padding: 15px 25px;
    font-size: $fs-normal;
    font-weight: 500;
  }

  &.button-size--xl {
    padding: 15px 20px;
    font-weight: 500;
  }

  &.button-size--large {
    padding: 10px 20px;
  }

  &.button-size--medium {
    padding: 5px 15px;
  }

  &.text-size--large {
    font-weight: 700;
    font-size: 1.2rem;
  }

  &.text-size--small {
    font-weight: 500;
    font-size: 0.8rem;
  }

  &.text-size--xs {
    font-weight: 500;
    font-size: 0.6rem;
  }

  &.text-color--grey {
    color: var(--primary-btn-bg-grey);
  }

  &.text-color--default {
    color: #333;
  }

  &:disabled {
    background: $primary-btn-bg-disabled;
    border-color: $primary-btn-bg-disabled;
    color: $primary-btn-text-light;
    cursor: initial;

    &:hover {
      background: $primary-btn-bg-disabled;
      border-color: $primary-btn-bg-disabled;
      color: $primary-btn-text-light;
    }
  }

  &.btn-color--tertiary {
    background: $apricot;
    border-color: $apricot;
    color: $primary-btn-text-light;

    &:hover {
      background: $primal-rage;
      border-color: $primal-rage;
      color: $primary-btn-text-light;
    }

    &:disabled {
      background: $primary-btn-bg-disabled;
      border-color: $primary-btn-bg-disabled;
      color: $primary-btn-text-light;
      cursor: initial;

      &:hover {
        background: $primary-btn-bg-disabled;
        border-color: $primary-btn-bg-disabled;
        color: $primary-btn-text-light;
      }
    }
  }

  &.btn-color--secondary-grey {
    background: $secondary-btn-bg-grey;
    border-color: $secondary-btn-bg-grey;
    color: $primary-btn-text-light;
  }

  &.btn-color--quaternary {
    background: $secondary-bg-light;
    border-color: $secondary-bg-light;
    color: $primary-btn-text-blue;

    &:hover {
      color: $primary-btn-text-blue;
    }
  }

  &.btn-color--senary {
    background: $apricot;
    border-color: $apricot;
    color: $primary-btn-text-light;

    &:hover {
      background: $primal-rage;
      border-color: $primal-rage;
      color: $primary-btn-text-light;
    }
  }
}
