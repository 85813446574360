@import "/src/Components/00.base/styles/variables";

svg {
    &.red {
        fill: $primary-btn-bg-dark;
    }

    &.green {
        fill: $primary-btn-bg-light;
    }
}

.helperText {
    margin-top: 10px;
    font-size: 14px;
    color: #3c3c3c;
}

.gridItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.deleteAgent {
    padding: 10;
    align-self: center;
}

.inputAgent {
    width: 45%;
}

.permissionInput {
    width: 30%;
}

.label {
    font-weight: 700;
    font-size: 14px;
    margin-block: 0.5rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mute-mail--wrap {
    margin-bottom: 10px;
    width: 20%;

    .mute__text {
        display: inline-block;
        vertical-align: top;
        padding-top: 6px;
        margin-left: 10px;
    }

    .mute__switcher {
        display: inline-block;

        .react-switch-handle {
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 25%);
        }
    }
}