@import '/src/Components/00.base/styles/variables';

.title-desc-wrap {
  color: $primary-text-grey-bold;

  &.text-size--xl {
    font-size: $fs-xl;
  }

  &.text-size--lg {
    font-size: $fs-lg;
  }

  &.text-size--normal {
    font-size: $fs-normal;
  }

  &.text-size--md {
    font-size: $fs-md;
  }

  &.text-size--sm {
    font-size: $fs-sm;
  }
}
