.totalContainer {
  display: flex;
  margin-block: 10px;
  flex: 1;

  &:nth-last-child(1) {
    font-weight: 700;
  }

  .totalTitle {
    flex: 0.3
  }

  .totalDesc {
    flex: 0.7;
    text-align: right;
  }
}