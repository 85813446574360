@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.manage-deal-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
  min-height: 100vh;
}

.step-title__container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;

  button {
    max-height: 60px;
  }
}

.manage-deal-screen .loader {
  width: 53%;
}

.manage-deal-screen .center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.manage-deal-screen .main-page-container .deal-information {
  padding: 0 20px;
  width: 53%;

  h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .sync-button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

    &:hover span {
      color: $primary-text-default;
    }
  }
}

.deal-information .step-details-container {
  border: 1px solid $primary-box-border;
  margin-top: 20px;
  padding: 15px;
}

.deal-information .header-step .backto-steps {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #e91e63;
  font-size: 14px;
  font-weight: bold;
}

.step-details-container .send-instructions .loader,
.main-page-container .deal-information .center .loader,
.step-details-container .step-title .loader {
  display: flex;
  gap: 5px;
  user-select: none;
}

.step-details-container .step-title .loader {
  margin-left: 15px;
  padding-top: 4px;
}

.main-page-container .deal-information .center .loader {
  margin-top: 1em;
}

.step-details-container .step-title .copy-rfq-button {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  padding-top: 1px;
  vertical-align: top;
}

.manage-deal-screen .related-documents {
  border-top: 1px solid #EFECED;
  padding-top: 30px;
}

@media only screen and (max-width: 1160px) {
  .manage-deal-screen .main-page-container .deal-information {
    width: 100%;
  }
}

@media only screen and (max-width: 710px) {
  .step-details-container .step-title h3 {
    font-size: 19px;
  }

  .manage-deal-screen .deal-description {
    flex-direction: column;
    padding: 0;
  }

  .manage-deal-screen .deal-description .edit-deal-button-container {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
  }

  .manage-deal-screen .deal-description .edit-deal-button-container .edit-deal-button {
    width: 90%;
    margin: 0;
  }

  .manage-deal-screen .deal-description .deal-info {
    margin-top: 15px;
  }

  .manage-deal-screen .step-group-container .step-group {
    font-size: 20px;
  }

  .manage-deal-screen .step-group-container .steps {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .manage-deal-screen .step-group-container .step+.step::before {
    content: none;
  }

  .manage-deal-screen .step-group-container .step .step-content {
    align-items: flex-start;
    gap: 10px;
  }

  .manage-deal-screen .step-group-container .step .step-content .step-border {
    width: calc(100% - 38px);
    border: none;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0;
  }

  .manage-deal-screen .step-group-container .step {
    border: 1px solid #EFECED;
    padding: 10px;
  }

  .manage-deal-screen .step-group-container .step .step-logo {
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin: 0;
    font-size: 11px;
  }

  .manage-deal-screen .step-group-container .step .step-content .step-border .step-info h5 {
    font-size: 100%;
  }

  .manage-deal-screen .step-group-container .step .step-content .step-border .step-info p {
    font-size: 90%;
  }

  .manage-deal-screen .step-group-container .step .step-content .step-border .step-actions {
    align-items: baseline;
    margin-top: 5px;
  }

  .manage-deal-screen .step-group-container .step .step-content .step-border .step-actions .remove-step {
    width: auto;
    height: auto;
  }
}

.manage-deal-screen .main-page-container .deal-information .archive-deal-buttons {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 650px) {
  .manage-deal-screen .deal-information .header-step .backto-steps {
    text-align: left;
    margin-bottom: 10px;
  }

  .manage-deal-screen .deal-information .step-details-container .steps-circles {
    max-width: 100%;
  }

  .manage-deal-screen .deal-information .step-details-container .steps-circles .circle {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 11px;
  }

  .manage-deal-screen .step-details-container .send-instructions {
    flex-direction: column;
  }

  .manage-deal-screen .step-details-container .send-instructions .finish-step-button {
    text-align: left;
    margin-top: 10px;
  }

  .manage-deal-screen .step-inputs-form .new-sample-button,
  .manage-deal-screen .step-inputs-form .discard-button,
  .manage-deal-screen .step-inputs-form .save-button {
    width: 100%;
    margin-top: 10px;
  }

  .manage-deal-screen .related-documents .group-name {
    flex-direction: column;
    height: auto;
  }

  .manage-deal-screen .related-documents .no-files {
    margin: 0;
  }

  .manage-deal-screen .related-documents .attach-file-button {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 420px) {
  .manage-deal-screen .main-page-container .deal-information {
    padding: 0;
  }

  .manage-deal-screen .deal-description .deal-info {
    flex-direction: column;
  }
}

@include media(max-md) {
  .step-title__container {
    display: block;
    text-align: center;

    .stepper-wrap--v1 {
      margin-bottom: 20px;
    }
  }
}