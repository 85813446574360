@import '/src/Components/00.base/styles/variables';

.group {
  margin-bottom: 25px;
  margin-top: 15px;
}

.groupName {
  p {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    font-weight: bold;
    width: 30%;
  }

  .buttons {
    display: inline-block;
    width: 70%;
    text-align: right;

    button {
      margin-left: 15px;
    }
  }

  .loader {
    display: inline-block;
    width: auto;
  }
}

.uploadFileWrap {
  & > div {
    margin-top: 0;
  }
}

@media (min-width: 320px) {
  .groupName {
    .buttons {
      button {
        margin: 10px 0;
        width: 100%;
      }
    }
  }
}

@media (min-width: 480px) {
  .groupName {
    .buttons {
      button {
        margin: 0 0 0 15px;
        width: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .groupName{
    height: auto;

    p {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }

    .buttons {
      display: block;
      width: 100%;
      text-align: center;

      button {
        display: block;
        margin: 0 0 20px;
        width: 100%;
      }
    }
  }
}
