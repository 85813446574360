.highcharts-legend-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
}

@media (max-width: 768px) {
  .highcharts-legend-item {
    width: 130px;
  }
}

@media (min-width: 768px) {
  .highcharts-legend-item {
    width: 150px;
  }
}

.highcharts-legend-key {
  font-weight: normal;
  font-size: 13px;
}

.highcharts-legend-value {
  font-weight: normal;
  font-size: 12px;
}
