.field {
    font-size: 18px;
    padding: 8px 16px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}