@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.createContainer {
  margin: 0 auto;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  h3 {
    padding-bottom: 5px;
    color: $primary-text-default;
    font-size: $fs-xxl;
  }

  button {
    font-size: $fs-normal;
  }
}

.subHeader {
  color: $secondary-text-grey;
  font-size: $fs-md;
}

.stepperWrap {
  margin-bottom: 40px;
}

@include media(md) {
  .createContainer {
    margin: 0 auto;
    width: 55%;
  }
}