.quote-container {
  .copy-rfq-button {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    padding-top: 1px;
    vertical-align: top;
  }

  .loader {
    display: flex;
    gap: 5px;
    user-select: none;
    margin-left: 15px;
    padding-top: 4px;
  }

  .group {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    .input-container {
      margin: 0;
    }

    .group-item {
      width: 48%;
    }
  }

  .input-container {
    margin-bottom: 30px;

    .input-label {
      font-size: 18px;
    }
  }

  .select-container {
    margin: 10px 0 0;
    padding: 0 10px;

    input#selected-input {
      margin: 0;
    }

    .choice {
      padding: 0;
      width: 100%;
    }
  }

  .select-inventory-container {
    margin: 10px 0 0;
    padding: 0 10px;

    input#selected-input {
      margin: 0;
    }

    .choice {
      padding: 0;
      width: 100%;

      .select-inventory__btn-wrap {
        padding-top: 10px;
      }
    }
  }

  .MuiBox-root {
    margin: 10px 0 0 !important;
    border: 1px solid #efebeb !important;
    padding: 0 10px !important;
  }
}

@media only screen and (max-width: 640px) {
  .quote-container {
    .group {
      display: block;
      margin: 30px 0;
    }
  }
}