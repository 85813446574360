@import '/src/Components/00.base/styles/variables';

.open-messages{
    position: fixed;
    right: 0;
    background-color: $primary-btn-bg-light;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 5px;
    display: flex;
    cursor: pointer;
}

.open-messages svg{
    fill: #FFF;
    width: 22px;
    height: 22px;
}