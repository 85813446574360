@import '/src/Components/00.base/styles/variables';

.upload-file-container{
    width: 100%;
    margin-top: 30px;
}

.dropzone{
    width: 100%;
    height: 100px;
    border: 2px dashed #999999;
    background-color: #FFF;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;

    svg {
        fill: $primary-text-light;
    }
}

.dropzone.accept{
    border: 2px dashed #00e676;
}

.dropzone.reject{
    border: 2px dashed #ff1744;
}

.dropzone > div{
    width: 100%;
}

.dropzone .text{
    min-width: 168px;
    width: 45%;
    font-size: 12px;
}

.dropzone .text h5 {
    font-size: 13px;
    display: flex;
    gap: 5px;
}

.dropzone .text h5 span {
    color: $primary-text-light;
}