@import '/src/Components/00.base/styles/variables';

.pagination-container {
  .MuiPagination-root {
    .MuiPaginationItem-root {
      border: none;
      background: none;
      color: $primary-text-default !important;
      border-radius: 6px;
    }

    .Mui-selected {
      border-color: $primary-text-light !important;
      background: $primary-text-light !important;
      color: $primary-btn-bg-light-hover !important;
    }

    .MuiSvgIcon-root {
      font-size: $fs-xxl;
    }
  }
}
