@import '/src/Components/00.base/styles/variables';

.details {
  color: #5B6275;
  padding-block: 0.2rem;
  min-height: 8rem;
  max-height: 10rem;

  &.visible {
    animation: fadeIn 0.3s ease-in;
  }

  .detailsItem {
    display: flex;
    font-size: $fs-sm;
    padding-block: 0.1rem;

    span {
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}