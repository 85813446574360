@import '/src/Components/00.base/styles/variables';

.placeholder {
  color: $placeholder-text;
}

.searchWrap {
  margin: 5px;
}

.selectorContainer {
  position: relative;
  margin-bottom: 5px;

  &:focus-visible {
    outline: none;
  }

  .select {
    &:focus-visible {
      outline: none;
    }

    &.defaultValue {
      color: $placeholder-text;
    }

    img {
      vertical-align: top;
      padding-top: 2px;
      padding-right: 5px;
    }

    input {
      background: none;
      cursor: pointer;
    }

    .actions {
      height: 100%;
      position: absolute;
      top: 18%;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .dropdown {
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    background-color: $primary-bg-light;
    border-radius: 5px;
    box-shadow: $dropdown-shadow;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    top: 100%;
    cursor: pointer;

    .placeholder {
      color: $placeholder-text;

      img {
        vertical-align: top;
        padding-top: 2px;
        padding-right: 5px;
      }
    }

    .inventoryOption {
      border-bottom: 1px solid $input-border;
      padding: 17px 18px;
      font-size: $fs-lg;
      cursor: pointer;

      .code {
        font-size: 13px;
      }

      .name {
        color: #000;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .option {
      padding: 17px 18px;
      cursor: pointer;
    }

    .highlight {
      background-color: $primary-bg-grey;
    }
  }
}

.fieldLoader {
  margin-top: 40px;
}

.calculatorOptions {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;

  .key {
    font-weight: bold;
    font-size: $fs-md;
  }

  .value {
    padding-top: 1px;
    font-size: $fs-sm;
  }
}

.contractContainer {
  .select {
    .actions {
      top: 17%;
    }
  }
}

.contractInput {
  display: flex;
  justify-content: space-between;
  outline: none;
  border: 1px solid $input-border;
  padding: 17px 18px;
  width: 100%;
  font-size: $fs-normal;
  box-sizing: border-box;
  border-radius: 4px;
  transition: $transition;

  &::placeholder, .placeholder {
    color: #8b8b8b;
    font-size: $fs-normal;
    font-weight: 400;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .key {
    font-weight: bold;
    font-size: $fs-md;
  }

  .value {
    margin-right: 20px;
    padding-top: 1px;
    font-size: $fs-xs;
  }
}
