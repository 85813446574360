@import "/src/Components/00.base/styles/variables";

.filesContainer {
  display: flex;
  flex-direction: column;

  .file {
    border: 1px solid $primary-separation-line-grey;
    padding: 10px 0px 10px 5px;
    margin-top: 15px;
    // width: 90%;
    // box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;

    .fileName {
      display: flex;
      gap: 10px;

      svg {
        fill: $primary-text-light;
      }

      .fileNameText {
        color: $primary-text-default;
        font-size: 15px;
        width: 25em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fileInformation {
      display: flex;
      color: $secondary-text-grey;
      margin-top: 10px;

      p + p::before {
        content: "|";
        margin: 0 10px;
        color: #efeced;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .filesContainer {
    .file {
      .fileName {
        .fileNameText {
          width: 10em;
        }
      }
    }
  }
}
