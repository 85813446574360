@import '../../00.base/styles/variables';

.popup{
    z-index: 100;
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 1rem;
    border-radius: 10px;
}

.popup.success{
    color: $success-text;
    background-color: $success-bg;
    border: 1px solid $success-border;
}

.popup.error{
    color: $error-color;
    background-color: $error-bg;
    border: 1px solid $error-color;
}

.visible{
    animation: fadeIn 0.3s ease-in;
}

.invisible{
    animation: fadeOut 0.3s ease-in;
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        display: block;
        opacity: 1;
    }

    100% {
        display: none;
        opacity: 0;
    }
}