.container {
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    label {
      margin-bottom: 20px;

      input {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}