@import '/src/Components/00.base/styles/variables';

.message {
  margin-top: 20px;
  text-align: center;

  a {
     text-decoration: none;
     color: $primary-navigation-hover;
 }
}
