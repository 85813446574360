.create-deal-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.create-deal-page .main-page{
    position: relative;
    height: 100%;
    max-width: 1270px;
    box-sizing: border-box;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    min-height: calc(100vh - 340px);
}