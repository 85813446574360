@import "/src/Components/00.base/styles/variables";

.container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
