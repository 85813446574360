@import '/src/Components/00.base/styles/variables';

.textarea {
    font-size: 16px;
    resize: none;
    padding: 8px 16px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $input-border;
    font-family: Plus Jakarta Sans, Helvetica, Arial, sans-serif;

    &:focus {
        outline: 1px solid $input-border-selected;
    }

    &:disabled {
        outline: 1px solid $input-border;
        background-color: $input-disabled;
        color: #ACAAA8
    }
}
