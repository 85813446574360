@import '@base/styles/variables';

.hideMessages {
  display: flex;
  gap: 10px;
  font-size: $fs-sm;
  align-items: center;

  p {
    font-size: $fs-normal;
  }
}

.labelWrap {
  & > div > div {
    margin-bottom: 0;
  }
}
