@import '/src/Components/00.base/styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  margin: 5px;
  border: 1px solid $input-border;
  border-radius: 4px;

  input#input {
    display: inline-block;
    margin-top: 0;
    border: none;
    border-radius: 4px;
    padding: 13px 10px;
    font-size: 18px;
    line-height: 18px;
    box-sizing: border-box;
  }

  button {
    display: inline-block;
    min-width: 50px;
  }
}
