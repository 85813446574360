@import '/src/Components/00.base/styles/variables';

.priceBreakdown {
  margin-top: 10px;

  button {
    display: block;
    padding: 0;
    width: 100%;
    text-align: left;
    font-size: $fs-sm;
    text-transform: capitalize;
  }
}