@import '/src/Components/00.base/styles/variables';

.Mui-selected {
  background-color: $primary-btn-bg-light !important;
}

.old-date--wrap {
  .MuiBox-root  {
    position: relative;
    border-radius: 4px;
    border: 1px solid $input-border;
    padding: 13px 10px;

    input {
      background-color: unset !important;
    }
  }
}

