@import '/src/Components/00.base/styles/variables';

.dealsList {
  padding: 10px 20px 10px 0;
}

.deal {
  display: flex;
  margin: 10px 0;

  & > * {
    width: 100%;
  }
}

.showMore {
  width: 100%;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  color: $primary-text-light;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
   text-decoration: underline;
  }
}

@media only screen and (max-width: 800px) {
  .deal {
    & > * {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 620px) {
  .dealsList {
    display: block;
    padding: 0;
  }
  .deal {
    width: 100%;
  }
}
