@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.servicesStepper {
  display: block;
  width: 100%;
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;
}

.servicesMainFields {
  margin-bottom: 3em;
}

.financingSwitcher {
  width: 35%;

  &>div {
    margin: 0.5em 0 0em;
  }
}

.requestServicesWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectInput {
  width: 40%;
  margin-top: 0px;
}

.imageWrap {
  img {
    display: block;
    margin: 40px auto;
    max-width: 100%;
    width: 200px;
  }
}