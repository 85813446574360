@import '/src/Components/00.base/styles/variables';

.dealScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.container {
  position: relative;
  height: 100%;
  max-width: 1270px;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  min-height: calc(100vh - 210px);
}

.listViewContainer{
  max-width: none;
  padding: 30px 30px;
  .dealsContainer {
    width: 100%;
  }
}

.dealsContainer {
  width: 100%;
}

.paginationWrap {
  margin-top: $item-bottom-margin;
}

.showMore{
  width: 100%;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  color: $primary-text-light;
  margin-top: 20px;
  cursor: pointer;

 &:hover {
   text-decoration: underline;
 }
}

.dealScreen {
  .dealsHeader {
    display: flex;
    justify-content: space-between;

    .switchWrap {
      padding-top: 5px;
      margin-right: 10px;
      color: #fff;
    }

    .section {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      gap: 20px;
    }
  }

  .switchButton {
    .icon{
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .switchIconsList {
    width: 50%;
    font-size: 1.2rem;
    text-align: right;
  }

  .switchIconsGrid {
    width: 50%;
    font-size: 1.2rem;
    text-align: right;
  }
}


@media only screen and (max-width: 1160px) {
  .dealsContainer {
    width: 100%;
    border: none;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    min-height: calc(100vh - 328px);
  }

  .dealScreen {
    .dealsContainer {
      width: 100%;
    }
  }

  .dealsContainer {
    border: none;
  }
}
