.container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 10px;

  span {
    font-weight: 700;
    font-size: 12px;
  }
}