@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.container {
  margin-bottom: $item-bottom-margin;

  &:focus-visible {
    outline: none;
  }
}

.field {
  outline: none;
  border: 1px solid $input-border;
  padding: 17px 18px 17px 10px;
  width: 100%;
  font-size: $fs-normal;
  box-sizing: border-box;
  border-radius: 4px;
  transition: $transition;

  &:disabled{
    background-color: $input-disabled;
  }

  &::placeholder {
    color: #8b8b8b;
    font-size: $fs-normal;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.error {
  border: 1px solid $primary-btn-bg-dark;
}

.noLabel {
  margin-top: 0;
}

@include media(md) {
  .noLabel {
    margin-top: 29px;
  }
}
