.edit-deal{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.edit-deal  .main-page{
    box-sizing: border-box;
    position: relative;
    height: 100%;
    max-width: 1470px;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    min-height: calc(100vh - 340px);
}