@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.stepper-wrap--v1 {
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    left: 0;
    margin: auto;
    width: 95%;
    height: 1px;
    background-color: $secondary-text-grey;
  }

  .MuiStepper-root {
    cursor: pointer;

    .MuiStep-root {
      &:first-child {
        .MuiStepLabel-root {
          align-items: start;

          .MuiStepLabel-label {
            text-align: left;
          }
        }
      }

      &:last-child {
        .MuiStepLabel-root {
          align-items: end;

          .MuiStepLabel-label {
            text-align: right;
          }
        }
      }
    }

    .MuiStepLabel-root.Mui-disabled {
      cursor: pointer;
    }

    .MuiStepConnector-root {
      display: none;
    }

    // all labels
    .default-label {
      padding-top: 8px;
      height: 8px;
      cursor: pointer;

      .MuiStepConnector-root {
        left: -57%;
      }

      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          margin-top: 13px;
        }
      }
    }

    .status--pending-approval,
    .status--approved,
    .status--declined {
      &>.MuiStepLabel-root .MuiStepLabel-iconContainer {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background: $primary-text-light;
      }

      svg {
        padding: 4px;
        width: 16px;
        height: 16px;
        color: #fff;
      }

      .MuiStepLabel-label {
        color: $primary-text-light;
      }
    }

    .status--neutral {
      &>.MuiStepLabel-root .MuiStepLabel-iconContainer {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background: $primary-text-default;
      }

      svg {
        padding: 4px;
        width: 16px;
        height: 16px;
        color: #fff;
      }

      .MuiStepLabel-label {
        color: $primary-text-default;
      }
    }

    .status--request {
      &>.MuiStepLabel-root .MuiStepLabel-iconContainer {
        width: 35px;
        height: 35px;
        margin-top: -10px;
      }

      svg {
        width: 25px;
        height: 25px;
        color: $primary-btn-text-grey;
      }

      &:hover {


        svg {

          color: $primary-btn-text-light;
        }
      }

      .MuiStepLabel-label {
        color: $primary-text-pending;
      }
    }

    .status--active {
      .MuiStepLabel-iconContainer {
        margin-top: 9px;
        border-radius: 50%;
        background-color: $secondary-text-grey;

        svg {
          color: $secondary-text-grey;
        }
      }

      .MuiStepLabel-labelContainer {
        padding-top: 6px;

        .MuiStepLabel-label {
          color: $secondary-text-grey;
        }
      }

      .MuiStepLabel-root {
        cursor: pointer;

        &.Mui-disabled {
          cursor: pointer;
        }
      }
    }

    .active-step {
      svg {
        border: 6px solid $primary-text-light;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        color: #fff;
      }

      .MuiStepLabel-label {
        color: $primary-text-light;
      }
    }

    .selected-step {
      flex: 5;

      .MuiStepConnector-root {
        left: -25%;
        right: 20%;
      }

      svg {
        border-radius: 50%;
        outline: 3px solid $secondary-text-grey;
      }

      &.status--active {
        .MuiStepLabel-iconContainer {
          margin-top: 9px;
          background-color: $secondary-text-grey;
        }

        svg {
          color: $secondary-text-grey;
          border: none;
        }
      }

      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          font-weight: 700;
        }
      }
    }

    .MuiStepLabel-labelContainer {
      .MuiStepLabel-label {
        margin-top: 5px;
        font-weight: 400;
      }
    }

    // all svg inside the label
    .MuiSvgIcon-root {
      width: 8px;
      height: 8px;
    }

    // completed text
    span.Mui-completed {
      color: $primary-text-light;
    }
  }

  .MuiStepLabel-root {
    z-index: 2;
    position: relative;
    cursor: pointer;

    .MuiStepLabel-iconContainer {
      background-color: #fff;
    }
  }

  // LINE
  .MuiStepConnector-root {
    z-index: 1;
    left: -65%;
    right: 55%;
  }
}

.stepper-wrap--alternative {
  .MuiStepper-root {
    justify-content: space-between;
    width: 100%;
  }

  .MuiStep-root {
    flex: 0 0 auto;
  }
}

@include media(max-sm) {
  .stepper-wrap--v1 {
    min-width: 100%;

    .MuiStepper-root {
      .selected-step {
        flex: 2;

        .MuiStepLabel-labelContainer {
          word-break: break-word;
        }
      }

      .MuiStep-root {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}