@import '/src/Components/00.base/styles/variables';

.separationLine {
  color: $primary-text-default;
  margin: 0 1px;

  &.lightColor {
    color: $primary-separation-line-light;
  }
}



