@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';
@import '/src/Components/00.base/styles/animations';

.section {
  .calculatorContainer {
    animation: fadeIn 0.3s ease-in;
  }

  .productFieldContainer {
    animation: fadeIn 0.3s ease-in;
  }
}

.accordionWrap {
  position: relative;
}

.addInventoryBtnWrap {
  z-index: 5;
  position: absolute;
  top: 3px;
  right: 0;

  button {
    text-transform: initial;
  }
}

.textWrap {
  margin-bottom: 20px;
}

.addInventoryWrap {
  text-align: right;
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 1;

  button {
		&:disabled {
      cursor: not-allowed;
			background: none;
			color: $primary-btn-bg-light;
		}
	}
}