@import '/src/Components/00.base/styles/variables';

.relatedDocuments {
  margin-top: 30px;
}

h3 {
  padding-bottom: 15px;
  font-size: 22px;
}

.relatedDocuments {
  margin-top: 15px;
  margin-bottom: 25px;
}

.noFiles {
  font-size: 14px;
}

.document {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #EFECED;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .documentDetails {
    width: 70%;
  }

  .fileStatus {
    border: 1px solid;
    padding: 0.1rem 0.3rem;
    color: #fff;
    border-radius: 3px;
    font-size: $fs-md;

    &.draft {
      background-color: #513F79;
      border-color: #513F79;
    }

    &.pendingApproval,
    &.pendingSignature,
    &.sentToDocusign {
      background-color: #F3C158;
      border-color: #F3C158;
    }

    &.signed,
    &.approved {
      background-color: #7ECB7A;
      border-color: #7ECB7A;
    }

    &.externalDocument {
      background-color: #7ECB7A;
      border-color: #7ECB7A;
    }

    &.cancelled,
    &.failedSubmission,
    &.failedDocusignDecline {
      background-color: #D73964;
      border-color: #D73964;
    }
  }
}

.documentTitle {
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 5px;
  cursor: pointer;

  .name {
    font-size: 17px;
    font-weight: bold;
  }

  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    color: $primary-text-light;
  }
}

.documentName {
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  font-size: 15px;
  text-overflow: ellipsis;
  cursor: pointer;
}

.updateButton {
  border: none;
  font-weight: bold;
  background-color: transparent;
  color: $primary-text-light;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: $primary-text-default;
  }
}

.fileActions {
  display: flex;

  .deleteAction {
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  .deleteButton {
    border: none;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
  }
}

.uploadInformation {
  margin-top: 10px;
  font-size: 14px;
  color: $secondary-text-grey;
  display: flex;
}

.uploadInformation p+p::before {
  content: '|';
  margin: 0 7px;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .uploadInformation {
    flex-direction: column;
  }

  .uploadInformation p+p::before {
    content: none;
  }

  .documentTitle {
    .documentName {
      width: 12em;
    }
  }

  .document {
    .documentDetails {
      width: 85%;
    }
  }
}