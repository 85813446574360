@import '/src/Components/00.base/styles/variables';

.container {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 20px;

  .line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}