@import '../../00.base/styles/variables';

.create-deal-body {
  p {
    font-size: 17.5px;
  }

  .no-references {
    font-size: 15px;
    color: #666666;
  }

  .trader {
    margin-top: 30px;
  }

  .input-wrap {
    position: relative;

    .input-loader {
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }
}

.create-deal--quote-step {
  padding-right: 40px;

  .create-deal__title {
    display: flex;

    h3 {
      font-size: 22px;
      text-transform: uppercase;
    }
  }
}

.create-deal-container {
  .explanatory-text {
    color: rgb(70, 70, 70);
    font-size: $fs-lg;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .checkmark .check {
    display: none;
  }

  .create-deal-body {
    display: flex;
    flex-direction: column;

    .references-titile {
      margin-bottom: 20px;
      font-weight: bold;
    }

    .reference-container {
      margin-bottom: 30px;
      margin-top: 30px;

      input {
        font-size: 18px;
        min-height: 50px;
        padding: 10px 25px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 5px;
        border: 1px solid $input-border;
        color: rgb(102, 102, 102);
      }

      .new-reference-button {
        margin-top: 10px;
      }
    }

    .reference-value {
      width: 45%;
    }

    .reference-type {
      width: 45%;
    }

    .reference {
      display: flex;
      gap: 25px;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      p {
        font-size: 14px;
      }

      .delete-ref {
        border: none;
        background-color: transparent;
        color: #E91E63;
        font-weight: bold;
        cursor: pointer;
        width: 10%;
        height: 50px;
        font-size: 15px;
      }
    }

    .buyer,
    .seller {
      margin-bottom: 10px;
    }

    .buyer .buyer-input,
    .seller .seller-input {
      min-height: 50px;
      line-height: 50px;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #efebeb;
      font-size: 17.5px;
      color: rgb(102, 102, 102);
    }

    .buyer .buyer-input.error,
    .seller .seller-input.error {
      border: 1px solid red;
    }

    .buyer .error-text,
    .seller .error-text {
      color: red;
      font-size: 14px;
    }
  }

  .select-container,
  .select-inventory-container {
    min-height: 50px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    border: 1px solid $primary-box-border;
  }

  .select-container input {
    color: #000 !important;
    font-size: 17.5px !important;
  }

  .readonly-input {
    margin-top: 10px;
    border: 1px solid $input-border;
    padding: 0 10px;
    width: 100%;
    min-height: 50px;
    box-sizing: border-box;
    background-color: #F9F9F9;
    color: rgb(102, 102, 102);
    font-size: 17.5px;
    line-height: 50px;
  }

  .options-list {
    &.opened {
      max-height: 40vh;
      overflow-y: auto;
      width: 100%;
      top: 51px;
    }

    .text-container {
      font-size: 18px;
    }
  }

  .checkmark {
    height: 15px;
    width: 15px;
    background-color: $primary-btn-bg-light;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .step.disabled .checkmark {
    background-color: #ccc;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: right;

    .loader {
      margin-top: 20px;
      align-items: center;
      user-select: none;
    }

    .back-button {
      button {
        padding: 15px 0;
        min-width: auto;
      }
    }

    button {
      width: auto;
    }
  }

  .loader {
    display: flex;
    gap: 10px;
    color: $primary-text-light;
    font-weight: 700;
    font-size: 13.3px;
    margin-top: 24px;
    margin-left: 22px;
    align-items: flex-end;
    user-select: none;
  }

  .create-deal-button {
    padding: 12px 20px;
    margin-right: 20px;
    font-weight: 700;
    margin-top: 20px;
  }

  .update-deal-button,
  .new-reference-button {
    padding: 12px 20px;
    margin-right: 20px;
    font-weight: 700;

    &:hover {
      outline: 1px solid #E91E63;
    }
  }

  .new-reference-button:hover {
    background-color: #FFF;
    color: #E91E63;
  }

  .update-deal-button {
    margin-left: 20px;
    color: #FFF;
    background-color: #E91E63;
  }

  .organization-name {
    margin-top: 10px;
    font-size: 14px;
    color: #3c3c3c;
  }

  .mute-mail--wrap {
    margin-top: 20px;
    margin-bottom: 10px;

    .mute__text {
      display: inline-block;
      vertical-align: top;
      padding-top: 6px;
      margin-left: 10px;
    }

    .mute__switcher {
      display: inline-block;

      .react-switch-handle {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 25%);
      }
    }
  }
}

.checkmark.checked .check {
  display: block;
}

@media only screen and (max-width: 620px) {
  .create-deal-container {
    .create-deal-button {
      width: 100%;
    }

    .create-deal-body {
      .reference-type {
        width: 100%;
      }

      .reference {
        flex-wrap: wrap;
        padding-bottom: 20px;
        padding-top: 20px;

        & +.reference::before {
          border-top: 1px solid #efebeb;
          width: 100%;
          content: "";
        }

        .delete-ref {
          width: 25%;
        }
      }

      .reference-value {
        width: 65%;
      }
    }

    .new-reference-button {
      width: 70%;
    }

    .update-deal-button {
      width: 30%;
    }

    .buttons-container {
      grid-column-gap: 15px;
    }
  }

  .reference-container .new-reference-button {
    width: 100%;
  }

  .create-deal--quote-step {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .create-deal-body {
    padding-right: 0 !important;
  }

  .create-deal-container {
    .buttons-container {
      text-align: right;
    }
  }
}

@media only screen and (min-width: 1161px) {
  .create-deal-container {
    margin: 0 auto;
    width: 55%;
  }

  .quote-container {
    margin: 15px 20px 15px 0;
    width: calc(100% - 15px)
  }

  .create-deal-body {
    padding-right: 40px;
  }

  .create-deal--quote-step {
    padding-right: 40px;
  }
}