.dynamic-grid-section {
  margin-bottom: 15px;

  &.add-margin {
    margin-right: 10px;
  }

  &.grid-columns--12 {
    display: block
  }

  &.grid-columns--11 {
    display: inline-block;
    width: calc(91.66% - 10px);
    vertical-align: top;
  }

  &.grid-columns--10 {
    display: inline-block;
    width: calc(83.33% - 10px);
    vertical-align: top;
  }

  &.grid-columns--9 {
    display: inline-block;
    width: calc(75% - 10px);
    vertical-align: top;
  }

  &.grid-columns--8 {
    display: inline-block;
    width: calc(66.66% - 10px);
    vertical-align: top;
  }

  &.grid-columns--7 {
    display: inline-block;
    width: calc(58.33% - 10px);
    vertical-align: top;
  }

  &.grid-columns--6 {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: top;
  }

  &.grid-columns--5 {
    display: inline-block;
    width: calc(41.66% - 10px);
    vertical-align: top;
  }

  &.grid-columns--4 {
    display: inline-block;
    width: calc(33.33% - 10px);
    vertical-align: top;
  }

  &.grid-columns--3 {
    display: inline-block;
    width: calc(25% - 10px);
    vertical-align: top;
  }

  &.grid-columns--2 {
    display: inline-block;
    width: calc(16.66% - 10px);
    vertical-align: top;
  }

  &.grid-columns--1 {
    display: inline-block;
    width: calc(8.33% - 10px);
    vertical-align: top;
  }
}

@media only screen and (max-width: 768px) {
  .dynamic-grid-section {
    margin-bottom: 20px;

    &.grid-columns--1,
    &.grid-columns--2,
    &.grid-columns--3,
    &.grid-columns--4,
    &.grid-columns--5,
    &.grid-columns--6,
    &.grid-columns--7,
    &.grid-columns--8,
    &.grid-columns--9,
    &.grid-columns--10,
    &.grid-columns--11 {
      display: block;
      width: 100%;
    }

  }
}