@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.stepTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px 0;
  background-color: $secondary-bg-grey;

  .titleSwitcherWrap {
    padding-top: 5px;
    padding-right: 10px;

    p {
      font-size: $fs-sm;
    }
  }
}

@include media(max-sm) {
  .stepTitle {
    justify-content: center;
  }
}
