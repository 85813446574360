@import "/src/Components/00.base/styles/variables";

.message {
  color: $primary-text-default;
  font-size: 15px;
  width: 25em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.originalMessage {
  color: $primary-text-default;
  font-size: 15px;
}

@media only screen and (max-width: 550px) {
  .message, .originalMessage {
    width: 10em;
  }
}
