@import '/src/Components/00.base/styles/variables';

.App {
  font-family: 'Plus Jakarta Sans', sans-serif;
  min-height: 100vh;
}

.button {
  background-color: $primary-btn-bg-light-hover;
  color: $primary-btn-text-light-hover;
  border: 2px solid $primary-btn-border-light;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: $primary-btn-bg-light;
  color: $primary-btn-text-light;
}