@import "/src/Components/00.base/styles/variables";

.container {
  margin: 30px 20px;
  background-color: #fff;
  display: flex;
  padding: 10px;

  &.originalMessageContainer {
    margin: 5px 10px;
  }

  &.containerDeleted {
    background-color: #efefef;

    .deletedMessageHeader {
      margin-top: 4px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .messageSubject {
    width: 100%;
    text-align: left;
    padding-left: 15px;
  }
}

.line {
  width: 1.5px;
  margin-top: 5px;
  height: calc(100% - 20px);
  background-color: $secondary-btn-bg-grey;
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  svg {
    background-color: $secondary-btn-bg-grey;
    color: $secondary-btn-text-grey;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 50%;

    path {
      fill: #fff;
    }

    &.red {
      background-color: $primary-btn-bg-dark;
    }

    &.green {
      background-color: $primary-btn-bg-light;
    }
  }
}

.senderDate {
  color: $secondary-text-grey;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.channel {
  color: $primary-btn-bg-light;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
  cursor: pointer;
}