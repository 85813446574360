@import '/src/Components/00.base/styles/variables';

.autocomplete-component {

  //margin-block: 10px;
  .MuiAutocomplete-root {

    .MuiInputBase-root {
      width: 100%;
      padding: 0;
      padding-left: 8px;
      padding-right: 8px;
      line-height: 1.5;

      input {
        font-weight: 400;
      }

      &.bold {
        .MuiOutlinedInput-input {
          font-weight: 700;
        }
      }

      &.arrows {

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: button;
        }
      }

      &.left {
        .MuiOutlinedInput-input {
          text-align: left;
        }
      }

      &.right {
        .MuiOutlinedInput-input {
          text-align: right;
        }
      }

      &.center {
        .MuiOutlinedInput-input {
          text-align: center;
        }
      }

      &.MuiInputBase-root.Mui-disabled {
        background-color: $input-disabled;

        &>fieldset {
          border-color: $input-border;
        }
      }

      .MuiOutlinedInput-input {
        padding: 12px 0px;
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        color: $primary-text-default;

        &::placeholder {
          font-weight: 400;
          color: $placeholder-text;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $input-border;
      }


      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid $input-border,
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid $input-border;
      }

      .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
        fill: #000;
      }

      .MuiInputAdornment-positionEnd p {
        font-weight: 700;
        font-size: 15px;
        color: #000;
      }

      .MuiInputAdornment-positionStart p {
        font-weight: 700;
        font-size: 12px;
      }

      &.Mui-error fieldset {
        border-color: $error-color;
      }
    }
  }
}