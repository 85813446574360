@import '/src/Components/00.base/styles/variables';

.reportsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.reportsPage .mainPage {
    position: relative;
    height: 100%;
    max-width: 1270px;
    box-sizing: border-box;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    min-height: calc(100vh - 340px);
}

.container {
    margin: 0 auto;
    width: 55%;
}

.mainForm {
    &:focus-visible {
        outline: none;
    }

    label {
        font-weight: 700;
    }

    input {
        max-width: 100%;
    }
}

.inputField {
    width: 33%;
    margin-right: 15px;
    margin-bottom: 15px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 15px;
}

.buttonsWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonInnerWrap {
        button {
            padding: 0;
            min-width: auto;
            color: $primary-text-default;
            transition: $transition;

            &:hover {
                color: $primary-text-light;
            }
        }

        .active {
            button {
                color: $primary-text-light;
            }
        }
    }
}