@import '/src/Components/00.base/styles/variables';

.uploadScreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .uploadContainer {
    background-color: #FFF;
    padding: 20px;
    min-width: 40%;
    max-height: 60%;
    overflow-y: overlay;

    .attachButton {
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .headerLine {
      display: flex;
      justify-content: space-between;

      svg {
        width: 13px;
      }
    }

    .document {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #EFECED;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .documentTitle {
        display: flex;
        position: relative;

        svg {
          fill: $primary-text-light;
        }

        .documentName {
          font-weight: bold;
          width: calc(100% - 60px);
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;
          margin-left: 10px;
        }
      }

      .uploadInformation {
        margin-top: 10px;
        font-size: 14px;
        color: $secondary-text-grey;
        display: flex;

        p+p::before {
          content: '|';
          margin: 0 7px;
        }
      }

      .deleteButton {
        border: none;
        background-color: transparent;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }


}


@media only screen and (max-width: 800px) {
  .uploadScreen {
    .upload-container {
      width: 60%;
    }

    .uploadContainer {
      .document {
        .upload-information {
          flex-direction: column;

          p+p::before {
            content: none
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .uploadScreen {
    .uploadContainer {
      width: 90%;
      height: auto;
      box-sizing: border-box;
    }
  }
}