@import '/src/Components/00.base/styles/variables';

.container {
  display: inline-block;
  // width: 18px;
  font-size: $fs-normal
}

.deleteButton {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;


  svg {
    color: $secondary-icon;
    transition: $transition;

    &:hover {
      color: $secondary-icon-hover;
    }
  }
}
