.container {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  
  .message {
    text-align: center;
    color: #646464;
    font-weight: bold;
  }
}