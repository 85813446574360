@import '/src/Components/00.base/styles/breakpoints';
@import '/src/Components/00.base/styles/variables';

.submitBtnContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &.link {
    & > div:first-child button {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cancelBtnWrap {
    margin-bottom: 20px;
  }

  .submitBtnWrap {
    margin-bottom: 20px;
    margin-inline: 5px;
    text-align: right;
  }

  button {
    min-width: 180px;
    width: auto;
  }
}

@include media(sm) {
  .rightAligned {
    text-align: right;

    .cancelBtnWrap {
      display: inline-block;
    }

    .submitBtnWrap, .cancelBtnWrap {
      display: inline-block;
    }

    .cancelBtnWrap {
      margin-right: 10px;
    }

    button {
      min-width: auto;
    }
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .cancelBtnWrap {
      display: inline-block;
      width: 50%;
    }

    .submitBtnWrap {
      display: inline-block;
      width: 50%;
      text-align: right;
    }

    button {
      width: auto;
    }
  }

  .submitBtnContainer.space-between {
    display: flex;
    flex-wrap: initial;
    justify-content: space-between;

    .cancelBtnWrap {
      display: inline-block;
    }

    .submitBtnWrap {
      display: inline-block;
      text-align: right;
    }

    button {
      width: auto;
      min-width: auto;
    }
  }
}
