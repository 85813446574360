@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

header {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  color: $primary-text-default;
  background-color: $primary-bg-light;

  a {
    text-decoration: none;
    color: $primary-text-default;
    align-items: baseline;
    font-weight: 700;
    font-size: $fs-md;

    &:hover {
      color: $primary-navigation-hover;
    }
  }

  li {
    list-style: none;
  }

  .container {
    display: flex;
    border-bottom: 2px solid #F2F2F2;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .menuWrapper {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .siteLogo {
    width: 150px;
  }

  .logoWrapper img {
    width: 150px;
    padding-top: 5px;
    height: auto;
  }

  .navigationList {
    display: flex;
    margin-left: 40px;
    height: 100%;

    li {
      height: 100%;
      display: flex;
      align-items: center;

      a {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .active {
      border-radius: 4px;
    }
  }

  .userContainer {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 5px;
  }
}

.nestedContainerWrap {}

.signInContainer {
  display: flex;

  &>svg {
    padding-top: 3px;
  }
}

header .navigationList li.active a,
header .navigationList li a:hover {
  color: $primary-text-light;
  box-shadow: inset 0px -3px 0px 0px $primary-text-light;
}

header .navigationList li+li::before {
  content: "";
  display: block;
  height: 13px;
  width: 1px;
  background-color: #efebeb;
  border: 0;
  margin: 0 20px;
  padding: 0;
}

header .user-container .select-language .dropdown-toggle {
  display: flex;
}

header .user-container .sign-in-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

header .user-container .sign-in-container p {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

header .user-container .select-language .flag-icon {
  margin: -1px 5px;
}

header .user-container .user-menu {
  margin-left: 10px;
}

header .user-container .user-menu .dropdown-menu.active {
  right: 5%;
  padding: 5px;
  line-height: 2.5;
}

header .user-container .user-menu .dropdown-menu.active .menu-item:hover {
  background-color: rgba(233, 30, 99, 0.1);
  border-radius: 4px;
}

@media only screen and (max-width: 1000px) {
  .menu-wrapper {
    width: calc(100% - 40.5px);
  }

  .logo-wrapper {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
  }
}

.searchWrap {}

.signInText {
  font-weight: 700;
  font-size: 13px;
  padding-top: 7px;
}