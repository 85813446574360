@import '/src/Components/00.base/styles/variables';

.Mui-selected {
  background-color: $primary-btn-bg-light !important;
}

.date-input-box {
  &.MuiBox-root {
    position: relative;
    border-radius: 4px;
    border: 1px solid $input-border;
    padding: 17px 10px 17px 40px;
    max-height: 13px;

    .MuiButtonBase-root {
      position: absolute;
      left: 0;
    }

    input {
      width: 100%;
      background-color: unset !important;
    }
  }

  &.date-error {
    border-color: $error-color;
  }
}

input.date-field {
  outline: none;
  border: 1px solid $input-border;
  padding: 14px 18px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: $transition;

  &:disabled {
    background-color: $input-disabled;
  }
}

.days-left--wrap {
  font-size: $fs-sm;
}

.MuiPaper-root {
  .MuiCalendarOrClockPicker-root {
    .MuiButtonBase-root {
      &.Mui-disabled {
        background-color: $input-disabled;
        color: $input-text-disabled;
      }
    }

    .MuiMonthPicker-root {
      button:disabled {
        background-color: $input-disabled;
        color: $input-text-disabled;
      }
    }
  }
}
