@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.container {
  &.grid {
    margin-bottom: 20px;

    .radioContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;

      .radioWrap {
        border: 1px solid $input-border;
        border-radius: 4px;
        padding: 17px 10px;
        height: min-content;
        cursor: pointer;

        input {
          margin-right: 10px;
          cursor: pointer;
          accent-color: #ff5d29; // Unique case where we need a specific color
        }
      }
    }
  }

  &.default {
    .radioWrap {
      display: block;
      padding: 5px 0;
      cursor: pointer;

      input {
        margin-right: 10px;
        cursor: pointer;
        accent-color: #ff5d29; // Unique case where we need a specific color
      }
    }
  }
}

@include media(xl) {
  .container {
    margin-bottom: 0;
  }
}