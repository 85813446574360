@import '/src/Components/00.base/styles/variables';

.footer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 35px;
  width: 100%;
  background-color: $ravenclaw;
  color: #FFF;
}

.logoWrap {
  img {
    margin-right: 20px;
    margin-left: 20px;
    width: 200px;
  }
}
