@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '/src/Components/00.base/styles/variables';

#upload-file-input {
  display: none;
}

.send-messages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 15px;

  .attach-files-container {
    .upload-file-container {
      .dropzone {
        height: 90px;
      }
    }
  }
}

.send-messages-container>p {
  font-size: 17px;
  color: #535353;
  width: 100%;
}

.messages-container .open-message-input {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(100, 100, 100);
  background-color: #FFF;
  text-transform: uppercase;
  border: 1px solid rgb(100, 100, 100);
  cursor: pointer;
  margin: 30px 0;
}

.messages-container .open-message-input:hover {
  outline: 1px solid gray;
}

.messages-container .open-message-input p {
  padding-left: 10px;
}

.send-messages-container #message-input {
  width: 100%;
  box-sizing: border-box;
  height: 110px;
  resize: vertical;
  border: 1px solid #E8EBF2;
  border-radius: 2px;
  margin-top: 5px;
  font-size: 13px;
  padding: 5px 10px;
}

.send-messages-container #message-input:focus-visible {
  outline: 1px solid #E8EBF2;
}

.send-messages-container .char-count {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: $secondary-text-grey;
  font-size: 13px;
  margin-top: 5px;
}

.message-input--container, .message-input--container .options-list {
  width: 100%;
}

.send-messages-container .attach-files-container {
  margin-top: 15px;
  width: 100%;
}

.send-messages-container .attach-files-container .dropzone {
  height: 70px;
}

.send-messages-container .step.disabled .checkmark {
  background-color: #ccc;
}

.send-messages-container .upload-file-container {
  margin: 0;
}

.send-messages-container .attach-file-button {
  background-color: transparent;
  color: #555555;
  border: 2px solid #555555;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 20px;
}

.send-messages-container .attach-file-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: 15px 0;
  margin-bottom: 25px;
  flex-direction: column;
}

.send-messages-container .attach-file-container .name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  background-color: #FFF;
  border: 1px solid #E8EBF2;
  padding: 5px 10px;
  box-sizing: border-box;
}

.send-messages-container .attach-file-container .name-container p {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

.send-messages-container .attach-file-container .name-container svg {
  fill: #e91e63;
}

.send-messages-container .attach-file-container .name-container .paperclip {
  transform: rotate(135deg);
  color: #e91e63;
  width: 21px;
}

.send-messages-container .attach-file-container .attached-file-name {
    color: #8b8b8b;
    width: 30em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.send-messages-container .send-message-button {
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: $primary-btn-bg-light;
  color: $primary-btn-text-light;

  &:hover {
    border: 2px solid $secondary-bg-dark;
    background-color: $secondary-bg-dark;
    color: $primary-btn-text-light;
  }
}

.send-messages-container .send-message-button .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}

.send-messages-container .error {
  font-size: 15px;
  color: $error-color;
  margin-top: 0;
}

@media only screen and (max-width: 450px) {
  .send-messages-container .checkmark {
    width: 20px;
  }
}
