@keyframes fadeIn {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear {
  from {opacity: 0;}
  to {opacity: 1}
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chartAnimation {
  0% {
    height: 85px;
    opacity: 0;
  }
  100% {
    height: 250px;
    opacity: 1;
  }
}

@keyframes moveDownAndShow {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}