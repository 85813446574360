@import '/src/Components/00.base/styles/variables';

.container {
  color: $primary-text-default;
  padding-block: 0.2rem;
  min-height: 8rem;
  max-height: 10rem;
  overflow-y: auto;

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  &::-webkit-scrollbar
  {
    width: 0.5rem;
    background-color: #F5F5F5;
  }
  
  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $secondary-text-grey;
  }

  &.visible {
    animation: fadeIn 0.3s ease-in;
  }

  .productName {
    font-weight: bold;
    font-size: $fs-sm;
    margin-block: 0.5rem;
    width: 16.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .productDetails {
    font-size: $fs-sm;
    display: flex;
    justify-content: space-between;
    padding-right: 0.6rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      font-weight: bold;
    }
  }
}


@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}