.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  &.grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }

  &.grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }

  &.grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 481px) {
  .grid {
    &.grid-3, &.grid-4, &.grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 20px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .grid {
    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .grid {
    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
