@import '/src/Components/00.base/styles/variables';

.create__stepper-wrap {
  .MuiStep-root {
    svg {
      color: $primary-text-light;
    }
  }

  .Mui-disabled {
    filter: grayscale(1);
  }
}