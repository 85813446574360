@import '@base/styles/variables';

.title {
    position: relative;
    color: $primary-text-default;
    font-weight: 700;
}

h2.title {
    margin-bottom: 40px;
    font-size: $fs-e;
    font-weight: 700;
}

h3.title {
    margin-bottom: 20px;
    font-size: $fs-xxl;
}

h4 {
    font-size: $fs-lg;
    font-weight: 700;
}

h5 {
    font-size: $fs-md;
    font-weight: 700;
}

h6 {
    font-size: $fs-sm;
    font-weight: 700;
}
