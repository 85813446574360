@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.mainForm {
  label {
    font-weight: 700;
    font-size: $fs-sm;
  }

  input {
    max-width: 100%;
  }
}

.container {
  .inputWrap {
    margin-bottom: 60px;
  }

  .loader {
    display: flex;
    gap: 5px;
    user-select: none;
    margin-left: 15px;
    padding-top: 4px;
  }
}

.removeProductBtn {
  margin-bottom: 20px;
  text-align: right;
}

.totalSection {
  margin-bottom: 20px;
  margin-right: 10px;

  .totalTitle, .totalDesc {
    display: inline-block;
    font-weight: 700;
  }

  .totalTitle {
    width: 30%;
  }

  .totalDesc {
    width: 70%;
    text-align: right;
  }
}

.buttonsContainer {
  text-align: right;

  button {
    margin: 0 0 10px;
    width: 100%;
  }
}

.newProductsWrap {
  margin-top: 40px;

  button {
    background: $primary-btn-bg-light-hover;
  }
}

.footer {
  margin-bottom: $section-bottom-margin;
}

@include media(md) {
  .buttonsContainer {
    button {
      margin: 0 10px 0 0;
      width: auto;
    }
  }
}