@import '/src/Components/00.base/styles/variables';

.progress {
  color: $primary-text-default;
  padding-block: 0.2rem;

  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $fs-xs;
    padding-bottom: 0.5rem;

    .stepName {
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }
    }
  }

  .progressBar {
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background-color: $tertiary-btn-bg-grey;
    position: relative;

    .progressDot {
      height: 7px;
      width: 7px;
      background-color: $primary-separation-line-light;
      position: absolute;
      top: -2.5px;
      border-radius: 50%;
      left: 0%
    }
  }
}