@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.top {
  margin-bottom: $primary-margin;

  .buyerInfo {
    display: flex;
    align-items: center;
    font-size: $fs-sm;
  }

  .mutedEmailWrap {
    margin-right: $primary-margin;

    .mutedEmail {
      height: 21px;

      .mutedEmailIcon {
        fill: $secondary-icon;
      }
    }
  }

  .codeDateWrap {
    font-size: $fs-sm;
    line-height: 21px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: $primary-margin;
  font-weight: 700;
  font-size: $fs-xxl;
}

.subTitle {
  margin-bottom: $primary-margin;
}

.buttonsWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .buttonInnerWrap {
    button {
      padding: 0;
      min-width: auto;
      color: $primary-text-default;
      transition: $transition;

      &:hover {
        color: $primary-text-light;
      }
    }

    .active {
      button {
        color: $primary-text-light;
      }
    }
  }
}

.dealIconCompleted {
  color: $primary-btn-bg-light;
}

.dealIconCancelled {
  color: $primary-text-dark;
}


@include media(sm) {
  .top {
    display: flex;
    justify-content: space-between;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
