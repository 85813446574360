@import '/src/Components/00.base/styles/variables';

.confirmation-screen {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-container {
  min-height: 85px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  background-color: $primary-bg-light;
  min-width: 25%;
  height: fit-content;
  border-radius: 2px;

  .buttons {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    gap: 15px;

    .confirm,
    .cancel {
      padding: 8px 0;
      width: 30%;
      font-weight: bold;
    }

    .confirm{
      background-color: $primary-btn-bg-light-hover;
      color: $primary-btn-text-light-hover;

      &:hover{
        background-color: $primary-btn-bg-light;
        color: $primary-btn-text-light;
      }
    }

    .cancel {
      background-color: $primary-btn-bg-dark-hover;
      color: $primary-btn-text-dark-hover;
      border: 2px solid $primary-btn-bg-dark-border-hover;

      &:hover {
        background-color: $primary-btn-bg-dark;
        color: $primary-btn-text-dark;
        outline: 1px solid $primary-btn-bg-dark-border;
      }
    }
  }

  .text {
    margin-bottom: 30px;
    text-align: center;

    strong {
      font-weight: bold;
      justify-content: center;
      align-items: center;
      color: $primary-text-default;
    }
  }
}

.confirmation-container .loader{
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-container .loader .spinner-container {
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #E91E63;
  }
}

@media only screen and (max-width: 800px) {
  .confirmation-container{
    width: 60%;
  }
  .confirmation-container .buttons{
    padding: 0;
    align-items: center;
  }
  .confirmation-container .buttons .confirm, .confirmation-container .buttons .cancel{
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .confirmation-container{
    width: 100%;
  }
}