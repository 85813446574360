@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/animations';

.priceBreakDownContainer {
  position: relative;
  display: flex;
  width: 100%;
  animation: heightAnimation 0.5s ease-in-out;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
