@import '/src/Components/00.base/styles/variables';

.relatedDocuments {
  margin-top: 20px;
}

.stepTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 0;
  background-color: $secondary-bg-grey;

  &.close {
    button {
      transition: $transition;
      color: $cloudy-blue;

      &:hover {
        color: $secondary-icon-hover;
      }
    }
  }

  &.open {
    button {
      transition: $transition;
      color: $secondary-icon-hover;

      &:hover {
        color: $cloudy-blue;
      }
    }
  }
}