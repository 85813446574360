@import '/src/Components/00.base/styles/variables';

.stepOneForm {
  & > div {
    margin-bottom: 20px;
  }
}

.muteEmailWrap {
  display: flex;
  margin-bottom: 40px;
}

.labelWrap {
  p {
    margin-top: 5px;
    font-size: $fs-normal;
  }
}

.muteSwitcher {
  cursor: pointer;

  p {
    font-weight: 700;
    font-size: $fs-md;
  }
}

.btnWrap {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}

.switchWrap {
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px 0;
  background-color: $primary-bg-grey;
}