.total-container {
  &.size--md {
    font-size: 16px;
  }

  &.size--lg {
    font-size: 20px;
  }

  &.size--xl {
    font-size: 24px;
  }

  .totalTitle {
    display: inline-block;
  }

  .totalDesc {
    display: inline-block;
  }
}
