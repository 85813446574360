@import '/src/Components/00.base/styles/variables';

.steps-list .step-group-container{
    padding: 30px 0;
}

.steps-list .step-group-container + .step-group-container{
    border-top: 1px solid #EFECED;
}

.step-group-container .step-group{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
}

.step-group-container .step+.step::before{
    content: '│';
    color: #d9d9d9;
    margin-left: 20px;
}

.step-group-container .step .step-content{
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.step-group-container .step .step-logo{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 1.5px solid $primary-btn-bg-light;
    border-radius: 100px;
    margin: 10px 0;
    color: $primary-text-light;
}

.step-group-container .step .step-logo.done{
    border-color: $primary-btn-bg-light;
    background-color: $primary-btn-bg-light;
    color: $primary-btn-text-light;
}

.step-group-container .step .step-content .step-border{
    border: 1px solid #EFECED;
    width: calc(100% - 70px);
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
}

.step-group-container .step .step-content .step-border .step-info{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.step-group-container .step .step-content .step-border .step-info h5{
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.step-group-container .step .step-content .step-border .step-info p{
    color: #898989;
}

.step-group-container .step .step-content .step-border .step-actions{
    display: flex;
    gap: 20px;
}

.step-group-container .step .step-content .step-border .step-actions .step-button{
    border: none;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
}

.step-group-container .step .step-content .step-border .step-actions .step-button.edit-step{
    color: $primary-link;
}

.step-group-container .step .step-content .step-border .step-actions .step-button.view-step{
    color: $primary-text-grey
}

.step-group-container .step .step-content .step-border .step-actions .remove-step{
    width: 12px;
    height: 16px;
    cursor: pointer;
}

.step-group-container .step .step-content .step-border .step-actions .remove-step svg{
    width: 12px;
}