@import "/src/Components/00.base/styles/variables";

.subjectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .verified {
    fill: $warm-blue;
  }

  .verificationFailed {
    fill: $venetian-red;
  }

  .verificationPending {
    fill: $gamboge;
  }

  .viewOriginalMsg {
    fill: $azure;
  }

  .verificationDisabled {
    fill: $oslo-grey;
  }
}

.subject {
  color: rgb(100, 100, 100);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
}