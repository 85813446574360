@import '/src/Components/00.base/styles/variables';

.container {
  margin: 60px auto;
  max-width: 420px;
  text-align: center;
}

.subHeader {
  margin-bottom: $item-bottom-margin;

  & > div {
    margin-top: 10px;
  }
}

.imageWrap {
  img {
    display: block;
    margin: 40px auto;
    max-width: 100%;
    width: 200px;
  }
}