@import '/src/Components/00.base/styles/variables';

.link {
  cursor: pointer;
  color: $primary-navigation;
  width: auto;
  
  a {
    color: $primary-navigation-hover;
    text-decoration: none;

    &:hover {
      color: $primary-navigation;
    }
  }
}
