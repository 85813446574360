@import '/src/Components/00.base/styles/variables';

.tableWrap {
  position: relative;
  overflow: hidden;

  .tableContainer {
    overflow: hidden;

    .table {
      position: relative;

      .tableHead {
        z-index: 2;
        position: sticky;
        top: 0;
        inset-block-start: 0;
        background-color: #fff;
      }

      .actionsColumn {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center
      }

      thead {
        tr>th:first-child {
          z-index: 3;
          position: sticky;
          left: 0;
          min-width: 300px;
          background-color: #fff;
        }

        th {
          min-width: calc(100vw * 0.08);
          color: $warm-blue;

          &:nth-child(-n + 3) {
            min-width: calc(100vw * 0.11);
          }

          &:first-child {
            min-width: calc(100vw * 0.17);
          }
        }
      }

      tbody,
      .tableBody {
        overflow-x: scroll;

        tr {
          td:first-child {
            z-index: 1;
            left: 0;
            min-width: 300px;
            background-color: #fff;
            position: sticky;
          }

          &:nth-child(odd) {
            background-color: $pastel-grey;

            td:first-child {
              background-color: $pastel-grey;
            }
          }
        }
      }
    }
  }
}

.sellerBorder {
  border-left: 5px solid $primal-rage;
}

.buyerBorder {
  border-left: 5px solid $primary-icon;
}

.loaderWrap {
  margin: $item-bottom-margin auto 0;
  width: 55%;
}