.deal-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.main-page-container {
  position: relative;
  height: 100%;
  max-width: 1270px;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  min-height: calc(100vh - 210px);
}

.deals-container {
  margin: 0 auto;
  width: 55%;
}

.deals-container--table-view {
  width: 100%;
}

.deal-screen {
  .deals-header {
    display: flex;
    justify-content: space-between;
  }
}

.deals-container .top-line {
  border-bottom: 1px solid #C0C0C0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.deals-container .top-line .titles {
  display: flex;
  margin-right: 10px;
}

.deals-container .top-line .titles h3+h3::before {
  content: '|';
  color: #000 !important;
  font-size: 20px;
  margin: 0 20px;
}

.deals-container .top-line .titles h3 {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 5px;
}

.deals-container .top-line .titles h3.pink {
  color: #e91e63;
}

.deals-container .create-deal-button {
  font-size: 14px;
  text-decoration: none;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1160px) {
  .deals-container {
    width: 100%;
    border: none;
  }
}

@media only screen and (max-width: 800px) {
  .main-page-container {
    min-height: calc(100vh - 328px);
  }

  .deal-screen .deals-container {
    width: 100%;
  }

  .deals-container {
    border: none;
  }
}

@media only screen and (max-width: 535px) {
  .deals-container .top-line .titles h3+h3::before {
    content: '';
    margin: 0;
  }

  .deals-container .top-line .titles {
    flex-direction: column;
  }
}