@import '/src/Components/00.base/styles/variables';

.button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 50%;
  padding: 10px;
  width: 25px;
  height: 25px;
  background-color: #EFEBEA;
  cursor: pointer;
  text-align: center;

  &.add {
    margin-left: auto;
    background-color: $primary-btn-bg-grey;
    transition: $transition;

    svg {
      color: $primary-btn-text-grey;
    }

    &:hover {
      background: $primary-btn-bg-light;

      svg {
        transition: $transition;
        color: $primary-btn-text-light;
      }
    }
  }
}