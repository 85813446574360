@import '/src/Components/00.base/styles/variables';

.prevArrow, .nextArrow {
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 100%;
  cursor: pointer;

  .arrowWrap {
    position: absolute;
    top: 48%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: $primary-btn-bg-light;
  }

  svg {
    width: 15px;
    height: 15px;
    color: $primary-btn-text-light;
    cursor: pointer;
  }
}

.prevArrow {
  top: 0;
  left: 310px;
  border-right: 1px solid rgba($primary-text-default, 0.1);

  .arrowWrap {
    left: 0;

    svg {
      padding: 2px 0 0 5px;
    }
  }
}

.nextArrow {
  right: 0;
  border-left: 1px solid rgba($primary-text-default, 0.1);
  background: $primary-bg-light;

  .arrowWrap {
    right: 0;

    svg {
      padding: 2px 0 0 3px;
    }
  }
}