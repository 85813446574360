@import '/src/Components/00.base/styles/variables';

.container {
  border: 1px solid #c4c4c4;
  padding: 0.5rem 0.8rem 0.8rem 0.8rem;
  display: grid;
  grid-template-columns: auto;

  .dealHeading {
    color: $primary-text-default;
    padding-block: 0.2rem;

    .dealInfoContainer {
      .dealCode {
        font-size: $fs-xs;
        text-align: right;
        margin-bottom: 0.8rem;
      }

      .dealTitleContainer {
        display: flex;
        flex-direction: row;
      }

      .dealTitle {
        font-weight: bold;
        font-size: $fs-normal;
        text-align: left;
        // width: 11rem;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      .lineClamp {
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
      }

      .dealMoreInfo {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $fs-xs;

        .info {
          width: 16rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .arrowIcon {
          cursor: pointer;
        
          &.transform {
            transform: rotate(180deg)
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
  }
}

.headingTop {
  display: grid;
  grid-template-columns: 20% 80%;
  margin-bottom: $primary-margin;

  svg {
    color: $secondary-icon;
  }
}

.codeDate {
  display: flex;
  justify-content: flex-end;
  font-size: $fs-xs;
  text-align: right;

  p {
    display: inline-block;
    margin-top: 3px;
  }

  svg {
    height: 18px;
  }
}

.deleteIcon {
  height: 20px;
  vertical-align: top;
}

.sellerBorder {
  border-bottom: 5px solid $primal-rage;
}

.buyerBorder {
  border-bottom: 5px solid $primary-icon;
}

.iconsWrapper {
  flex-direction: row;
  display: flex;
}

svg.verifiedIcon {
  fill: $warm-blue;
  width: 15px;
  height: 15px;
  margin: 1px 0 0 3px ;
}