@import '/src/Components/00.base/styles/breakpoints';
@import '/src/Components/00.base/styles/variables';

.wrap {
  margin-bottom: 20px;
}

.clearButton {
  display: none;
  font-size: 0.75rem;
  font-weight: 700;
  color: $primary-text-light;
  text-transform: uppercase;
}

@include media(md) {
  .clearButton {
    display: block;
  }
}
