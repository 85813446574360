.deal-invitation .content{
    min-height: calc(100vh - 706px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
}

.deal-invitation .content h1{
    color: #e91e63;
}