@import '/src/Components/00.base/styles/variables';

.labelWrap {
  margin-bottom: 10px;
  width: auto;
  max-height: 17px;
  color: $primary-text-default;
}

.label {
  font-weight: 700;
  font-size: $fs-md;

  margin-block: 0.5rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requiredIndicator {
  padding-left: 5px;
  color: $error-color;
  font-weight: 400;
  font-size: $fs-normal;
}