@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.messageWrap {
  z-index: 100;

  .messageIcon {
    width: 30px;
    cursor: pointer;
  }
}

.container {
  z-index: 100;
  position: absolute;
  top: 134px;
  right: 0;
  display: flex;
  border: 1px solid $secondary-box-border;
  border-bottom: 1px solid $secondary-box-border;
  background-color: $primary-bg-grey;

  & > div {
    margin: 10px auto;
  }
}

.buttonWrap {
  position: relative;

  .spinnerWrap {
    z-index: 1;
    position: absolute;
    right: -3px;
    bottom: 7px;
    border-radius: 50%;
    background-color: $primary-text-light;

    div > div {
      width: 10px;
      height: 10px;
    }
  }
}

@include media(md) {
  .container {
    top: 80px;
    width: 520px;
    max-height: 550px;
  }
}

@include media(xl) {
  .messageWrap {
    position: relative;
  }

  .container {
    top: 54px;
    width: 520px;
    max-height: 550px;
  }
}
