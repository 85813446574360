@import '/src/Components/00.base/styles/variables';

.name {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.hint {
  font-size: 13px;
  white-space: pre-wrap;
}

.placeholder {
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
}

.container {
  display: flex;
  justify-content: space-between;
  flex: 1;

  .key {
    font-weight: bold;
    font-size: $fs-md;
  }

  .value {
    padding-top: 1px;
    font-size: $fs-sm;
  }
}
