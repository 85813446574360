@import '/src/Components/00.base/styles/variables';

.description {
  span {
    color: $primary-btn-bg-light;
  }
}

.underBody {
  margin-top: $item-bottom-margin;
  //color: $primary-text-grey-bold;
}

.bottomMargin {
  margin-bottom: $item-bottom-margin;
}

.topMargin {
  margin-top: $item-bottom-margin;
}

svg.mutedEmailIcon {
  fill: $secondary-icon;
}

svg.notMutedEmailIcon {
  fill: $primary-icon;
}