@import '/src/Components/00.base/styles/variables';

.inputContainer {
  font-size: 18px;

  input {
    width: 100%;
    font-size: 18px;
    border: 1px solid $input-border;
    padding: 13px 10px;
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      outline: 1px solid $input-border-selected;
    }

    &:disabled {
      background-color: $input-disabled;

    }
  }

  &.error {
    input {
      border: 1px solid $error-color;
    }
  }

  p.errorText {
    margin-top: 0.5rem;
    font-size: $fs-normal;
    color: $error-color;
  }

  .inputLabel {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $input-label;
  }
}