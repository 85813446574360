@import '/src/Components/00.base/styles/variables';

.summaryContainer {
  margin-top: 8px;

  .summaryTitle {
    padding-top: 2px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: $fs-md;
  }

  .summaryText {
    margin-top: 12px;
    font-size: $fs-normal;

  }

  .totalText {
    margin-top: 12px;
    font-size: 22px;
  }
}
