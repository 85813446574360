@import '/src/Components/00.base/styles/variables';

.select-container {
    position: relative;
    background-color: $primary-bg-light;
    border: 1px solid $input-border;
    border-radius: 4px;
    cursor: pointer;
    color: rgb(102, 102, 102);
    // margin-bottom: 10px;
}

.select-container.error{
    border: 1px solid red;
}

.select-container:focus-visible{
    outline: none;
}

.select-container .choice {
    display: flex;
    width: calc(100% - 20px);
    height: 100%;
    min-height: 21px;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px;

    input {
        cursor: pointer;
    }
}

.select-container #selected-input{
    border: none;
    font-size: 18px;
    color: rgb(102, 102, 102);
    height: 100%;
    width: 100%;
}

.select-container #selected-input:disabled {
    cursor: pointer;
    background: none;
}

.select-container #selected-input:focus-visible{
    outline: none;
}

.options-list{
    display: none;
}

.options-list.opened{
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 9px 15px -3px rgba(0,0,0,0.39);
    z-index: 1000;
}

.options-list .option{
    cursor: pointer;
    min-height: 30px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.options-list .option.highlight{
    background-color: #E3E3E3;
}

.error-container{
    color: red;
    font-size: 15px;
}

.select-container .choice #selected {
    width: 100%;
}

.select-container .text-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $primary-text-default;
}

.select-container .arrow-container { 
    padding: 0 5px;
    font-size: 12px;
}
