@import '/src/Components/00.base/styles/variables';

.finalPriceWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  border-top: 1px solid $input-border;
  padding: 20px 15px 0 5px;
}

.buttonsWrap {

}

.saveButtons {
  display: flex;
  justify-content: space-between;
  text-align: right;

  & > div:last-child {
    margin-left: auto;
  }
}