.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 30px 20px;
  max-width: 1270px;
  width: 100%;
  box-sizing: border-box;
}