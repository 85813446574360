@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/breakpoints';

.searchContainer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F9F9F9;
  border: 1.5px solid $input-border;
  padding: 5px 0;
  margin-right: 20px;

  .searchInput {
    display: flex;
    width: calc(100% - 31px);
  }

  .searchIcon {
    margin-right: 15px;
    cursor: pointer;

    svg {
      padding-top: 5px;
    }
  }
}