@import '/src/Components/00.base/styles/variables';

.mui-form-wrap {
  .MuiFormControl-root {
    margin-bottom: 0;
  }
}

.MuiPaper-root.MuiMenu-paper {
  max-height: 225px;
}

.MuiList-root {
  .Mui-selected {
    background-color: $select-dropdown-selected !important;
  }
}

.MuiAutocomplete-popper {
  li.MuiAutocomplete-option {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    font-weight: 700;

    &[aria-selected="true"] {
      background-color: $select-dropdown-selected !important;
    }
  }

  .Mui-focused {
    background-color: $select-dropdown-selected !important;
  }
}

.mui-form-wrap {
  .MuiAutocomplete-root .MuiInputBase-root  {
    padding: 13px 14px;

    input {
      padding: 0;
    }

    .MuiList-root {
      .Mui-selected {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      padding: 13px 14px;
    }

    &.MuiInputBase-root.Mui-disabled {
      background-color: #EFEBEA;
      & > fieldset {
        border-color: $input-border;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $input-border;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border: 1px solid $input-border;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid $input-border;
    }

    .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
      fill: $select-arrow;
    }
  }
}
