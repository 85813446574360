@import '/src/Components/00.base/styles/variables';

.container {
    margin-bottom: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 20px 10px;
    margin-block: 35px;
}

.createFormHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
}

.headingContainer {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;

    p {
        margin-top: 3px;
    }
}

.stepInfo {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .serviceConcept {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        align-self: center;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.gray {
            color: $primary-text-grey
        }

        &.purple {
            color: $primary-link;
        }
    }


    h5 {
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 1px;
    }

    p {
        color: #898989;
        font-size: 14px;
    }
}

.summary {
    width: 33%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttonsContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}