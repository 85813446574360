@import '/src/Components/00.base/styles/variables';

.completeBtn {
  border: none;
  background-color: transparent;
  font-size: $fs-md;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;

  &:hover span {
    color: $primary-text-default;
  }

  span {
    color: $primary-text-light;
  }
}