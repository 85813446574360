@import '/src/Components/00.base/styles/variables';

.header__nester-dropdown--wrap {
  button {
    border: none;
    padding: 5px 0;
    min-width: 20px;
    color: initial;
    text-transform: initial;
    font-size: 13px;

    &:hover {
      border: none;
      outline: none;
      background: none;
    }
  }
}

.sub-header__envelope-wrap {
  display: block;
  margin-left: 20px;
  padding-top: 10px;
  vertical-align: top;
  text-align: center;

  img {
    width: 32px;
    height: 32px;
  }

  .messages-container {
    position: absolute;
    right: 0;
    top: 54px;
    overflow: hidden;
    width: 520px;
    max-height: 800px;
    background-color: #F9F9F9;
  }

  .messages-container .messages-list {
    max-height: 680px;
  }
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  p {
    font-size: $fs-md;
    line-height: 1.8;
  }
}