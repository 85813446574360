@import '/src/Components/00.base/styles/variables';
@import '/src/Components/00.base/styles/animations';

.container {
  margin-bottom: 20px;
}

.dropdownHeader {
  z-index: 1;
  position: relative;
  background: #fff;
  font-weight: 500;

  span {
    position: absolute;
    right: 10px;
  }
}

.desc {
  display: flex;
  padding-bottom: 10px;
}

.closed {
  svg {
    transform: rotate(0deg);
  }
}

.opened {
  svg {
    transform: rotate(180deg);
  }
}

.navDropdown {
  z-index: 0;
  position: relative;
  margin-top: 20px;
  border-top: 1px solid $primary-separation-line-grey;
  padding-top: 20px;
  animation: moveDownAndShow 0.3s ease-in-out;

  a {
    display: block;
    padding: 10px 0 10px 10px;
    font-weight: 500;
  }
}