@import '@base/styles/variables';

.container{
  display: flex;
  gap: 15px;
  cursor: pointer;
}

.filterIconsWrap {
  position: relative;
  top: 4px;
  left: 0;
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 20px;


  .arrowUp {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid $primary-box-border;
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 6px solid $primary-box-border;
  }

  &.ascWrap {
    .arrowUp {
      border-bottom-color: $primary-text-default;
    }
  }

  &.descWrap {
    .arrowDown {
      border-top-color: $primary-text-default;
    }
  }

  .filterIcons {
    width: 10px;
    height: 6px;
    cursor: pointer;
  }

  .asc {
    position: absolute;
    top: 2px;
    right: 1px;
  }

  .desc {
    position: absolute;
    top: 11px;
    right: 1px;
  }
}
