@import "/src/Components/00.base/styles/variables";

svg {
  &.verified {
    fill: $warm-blue;
  }
}

.originalValue {
  outline: 1px solid $input-border;
  background-color: $input-disabled;
  color: #acaaa8;
  padding: 0.8em;
  border-radius: 5px;
  max-height: 100px;
  overflow-y: auto;
}

.originalValueContainer {
  display: flex;
  gap: 10px;
}
.copyText {
  font-size: $fs-sm;
}

.copyButton {
  height: fit-content;
}

.hashRow {
  display: flex;
  gap: 10px;
  align-items: center;
  color: $secondary-text-grey;
  font-size: $fs-md;
}
