@import '/src/Components/00.base/styles/variables';

@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

.loaderContainer {
  z-index: 9999;
  display: flex;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);

  .loaderWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;

    .loader {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      border: 3px solid;
      border-color: $primary-text-light transparent;
      -webkit-animation: rotation 1s linear infinite;
      animation: rotation 1s linear infinite;
    }
  }
}