.messages-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.messages-screen .messages-container {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
}

.messages-screen .send-messages-container,
.messages-screen .messages-container .open-message-input {
    width: 100% !important;
}