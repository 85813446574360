.buttonsContainer {
  .saveButtons {
    display: flex;
    justify-content: space-between;
    text-align: right;

    div:last-child {
      margin-left: auto;
    }
  }

  .tooltipWrap {
    display: inline-block;
    margin-right: 10px;
  }
}