@import "/src/Components/00.base/styles/variables";

.title {

  svg {
    &.verificationFailed {
      fill: $venetian-red;
    }
  }
}
