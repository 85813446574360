@import '/src/Components/00.base/styles/variables';

.container {
  position: absolute;
  top: 0;
  right: -5px;
  width: 15px;
  height: 15px;
  background-color: $primary-remove-btn-bg;
  border-radius: 50%;
  transition: $transition;

  &:hover {
    background-color: $primary-btn-bg-light;
  }

  svg {
    width: 13px;
    height: 15px;
    fill: #fff;
  }
}