@import '/src/Components/00.base/styles/variables';


.create-step {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    //margin-bottom: 13px;
    // width: 22%;
    margin-left: 10px;

    &.disabled {
        cursor: default;
    }

    input {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    p {
        font-weight: 500;
        font-size: 16px;

    }
}

.checkmark {
    height: 15px;
    width: 15px;
    background-color: $primary-text-light;
    border: none;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.checkmark.checked .check {
    display: block;
}