@import '/src/Components/00.base/styles/breakpoints';
@import '/src/Components/00.base/styles/variables';

.container {
  margin: 20px auto 0;
  width: 100%;
  font-size: $fs-sm;

  strong {
    margin-right: 3px;
  }

  a {
    color: $primary-link;

    &:hover {
      color: $ravenclaw;
    }
  }
}

.contactWrap {
  display: inline;
}

@include media(lg) {
  .container {
    width: auto;
    margin: 0;
  }
}
