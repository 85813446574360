@import '/src/Components/00.base/styles/variables';

.messages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 43%;
    height: 100%;
    background-color: #F9F9F9;

    &.notification-container {
        width: 100%;
        height: auto;
        z-index: 100;
    }
}

.messages-container>.head {
    width: 95%;
    border-bottom: 1px solid #EFECED;
    color: #3C3C3C;
    display: block;
    justify-content: space-between;
    padding: 0 10px 20px;
    box-sizing: border-box;

    h3 {
        vertical-align: middle;
        margin-right: 10px;
    }

    .message-head--inner-wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .message-header {
            display: flex;
            flex-direction: row;
        }

        svg {
            fill: $primary-text-grey;
        }

        .channel {
            font-size: 12px;
            margin-top: -10px;
        }
    }

    .message-header__buttons-wrap {
        display: inline-block;
        vertical-align: middle;

        &>div {
            display: inline-block;
            margin-left: 10px;
        }
    }
}

.messages-container .head .hide-messages {
    display: flex;
    font-size: 12px;
    align-items: end;
    flex-direction: column;

    svg {
        fill: $primary-text-grey;
    }

    &>div,
    p {
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
    }
}

.messages-container .messages-list {
    width: 100%;
    overflow-y: auto;

    .missing-messages {
        padding: 20px;
        max-width: 95%;
        color: #646464;
        font-weight: bold;
        font-size: 15px;
    }
}

.messages-container .show-more {
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 0;
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: $primary-text-light;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.mobile__message--back-btn {
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
    color: $primary-btn-bg-light;
    text-align: left;

    &:hover {
        color: $primary-text-default;
    }

    svg,
    .back-text-wrap {
        vertical-align: middle;
    }

    svg {
        width: 17px;
        height: 17px;
    }
}

@media only screen and (max-width: 390px) {
    .messages-container>.head {

        .hide-messages {
            display: flex;
            justify-content: space-between;

            p {
                margin-left: 0;
            }
        }

        h3 {
            display: block;
            vertical-align: middle;
        }

        .message-head--inner-wrap {
            display: block
        }
    }
}

@media only screen and (max-width: 480px) {
    .messages-container>.head {
        display: block;
    }

    .messages-container>.head h3 {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1160px) {
    .messages-container>.head h3 {
        display: inline-block;
    }

    .message-container .files-container .file .file-information {
        flex-direction: column;
    }

    .message-container .files-container .file .file-information p+p::before {
        content: none;
    }

    .message-container .files-container .file .file-information p+p {
        margin-top: 2px;
    }
}

@media only screen and (min-width: 1161px) {
    .message-head--inner-wrap {
        padding-top: 20px;
    }
}