.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 25% 5% 25%;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .message {
      text-align: center;

      .title {
        color: #E91E6D;
        font-weight: bold;
        font-size: calc(1.5rem + 0.2vw);
        padding: 0 0 5% 0;
      }

      .text {
        color: #646464;
        padding: 1% 0;
        font-size: calc(1rem + 0.2vw);
      }
    }
  }
}

@media only screen and (max-width: 800) {
  .container{
    padding: 5%
  }
}

